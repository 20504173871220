import { Dots, NextLink, PageLink, Pagination, PreviousLink, Flex, SingleSelectOption, SingleSelect, Typography } from '@strapi/design-system';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import React, { useEffect } from "react";

export default function PaginationURLQuery({ 
  pageCount,
  activePage,
  updatePageSize,
  pageSize,
  updateActivePage,
}) {
  const location = useLocation();
  const boundaryCount = 1;
  const siblingCount = 1;
  const options = [20, 50, 100];

  const formatMessage = (message, values) => {
    return message.defaultMessage;
  }

  useEffect(() => {
    // If exist param page in url, and this is an integer, set currentPage to this value
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get('page'));
    if (!isNaN(page) && page !== activePage) {
      updateActivePage(page);
    }

    // If exist param pageSize in url, and this is an integer, set pageSize to this value
    const urlPageSize = parseInt(searchParams.get('pageSize'));
    if (!isNaN(urlPageSize) && urlPageSize !== pageSize) {
      updatePageSize(urlPageSize);
    }
  }, []);

  const range = (start, end) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i);
  };

  const startPages = range(1, Math.min(boundaryCount, pageCount));
  const endPages = range(Math.max(pageCount - boundaryCount + 1, boundaryCount + 1), pageCount);

  const siblingsStart = Math.max(
    Math.min(
      // Natural start
      activePage - siblingCount,
      // Lower boundary when page is high
      pageCount - boundaryCount - siblingCount * 2 - 1
    ),
    // Greater than startPages
    boundaryCount + 2
  );

  const siblingsEnd = Math.min(
    Math.max(
      // Natural end
      activePage + siblingCount,
      // Upper boundary when page is low
      boundaryCount + siblingCount * 2 + 2
    ),
    // Less than endPages
    endPages.length > 0 ? endPages[0] - 2 : pageCount - 1
  );

  const items = [
    ...startPages,

    // Start ellipsis
    // eslint-disable-next-line no-nested-ternary
    ...(siblingsStart > boundaryCount + 2
      ? ['start-ellipsis']
      : boundaryCount + 1 < pageCount - boundaryCount
      ? [boundaryCount + 1]
      : []),

    // Sibling pages
    ...range(siblingsStart, siblingsEnd),

    // End ellipsis
    // eslint-disable-next-line no-nested-ternary
    ...(siblingsEnd < pageCount - boundaryCount - 1
      ? ['end-ellipsis']
      : pageCount - boundaryCount > boundaryCount
      ? [pageCount - boundaryCount]
      : []),

    ...endPages,
  ];

  // Listening to the url changes
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get('page'));
    if (!isNaN(page) && page !== activePage) {
      updateActivePage(page);
    }
  }, [location]);
  
  const handleChange = (e) => {
    updatePageSize(e);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('pageSize', e);
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    // Update the URL with the new page size
    window.history.pushState({ path: newUrl }, '', newUrl);
  }

  const generateUrl = (page, pageSize) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', page);
    searchParams.set('pageSize', pageSize);
    return `${location.pathname}?${searchParams.toString()}`;
  }

  return (
    <>
      <Flex className="mt-5 bottom-toolbar" justifyContent="space-between" gap={2}>
        <Flex className="limiter" gap={2}>
          <SingleSelect
            size="S"
            aria-label={formatMessage({
              id: 'components.PageFooter.select',
              defaultMessage: 'Entries per page',
            })}
            // @ts-expect-error from the DS V2 this won't be needed because we're only returning strings.
            onChange={handleChange}
            value={pageSize}
          >
            {options.map((option) => (
              <SingleSelectOption key={option} value={option}>
                {option}
              </SingleSelectOption>
            ))}
          </SingleSelect>
          <Typography textColor="neutral600" as="span">
            {formatMessage({
              id: 'components.PageFooter.select',
              defaultMessage: 'Entries per page',
            })}
          </Typography>
        </Flex>
        <Pagination activePage={activePage} pageCount={pageCount}>
          <PreviousLink as={NavLink} active={false} to={generateUrl(activePage - 1, pageSize)}>
            {formatMessage({
              id: 'components.pagination.go-to-previous',
              defaultMessage: 'Go to previous page',
            })}
          </PreviousLink>
          {items.map((item) => {
            if (typeof item === 'number') {
              return (
                <PageLink
                  active={item === activePage}
                  key={item}
                  number={item}
                  as={NavLink}
                  to={generateUrl(item, pageSize)}
                >
                  {formatMessage(
                    { id: 'components.pagination.go-to', defaultMessage: `Go to page ${item}` },
                    { page: item }
                  )}
                </PageLink>
              );
            }

            return <Dots key={item} />;
          })}
          <NextLink as={NavLink} active={false} to={generateUrl(activePage + 1, pageSize)}>
            {formatMessage({
              id: 'components.pagination.go-to-next',
              defaultMessage: 'Go to next page',
            })}
          </NextLink>
        </Pagination>
      </Flex>
    </>
  );
};