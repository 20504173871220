import React, { useState, useContext } from "react";
import { GlobalContextState } from "../../context/globalContext";
import { GlobalContextDispatch } from "../../context/globalContext";
import { BaseHeaderLayout } from '@strapi/design-system';
import { ContentLayout } from "@strapi/design-system";
import { TextInput, Box, Grid, GridItem, Button, Alert } from '@strapi/design-system';
import { Check } from '@strapi/icons';
import { baseUrl } from "../../config";

const INITIAL_FORM_ERRORS = {
    password: false,
    passwordConfirmation: false
};

export default function EditProfileForm() {
    const dispatch = useContext(GlobalContextDispatch);
    const [status, setStatus] = useState(undefined);
    const [formError, setFormError] = useState(INITIAL_FORM_ERRORS);
    const user = useContext(GlobalContextState).user;
    const userUrl = `${baseUrl}/api/users/${user.id}`;
    const [loadingSubmit, setLoadingSubmit] = React.useState(false);

    const [formData, setFormData] = useState({
        password: '',
        passwordConfirmation: ''
    });


    function handleInputChange(event) {
        const { name, value } = event.target;
        if (value !== '') {
            setFormData({ ...formData, [name]: value });
        } else {
            delete formData[name];
        }
    }

    function validatePassword(password, passwordConfirmation) {
        if (password === passwordConfirmation) {
            setFormError((prevState) => ({ ...prevState, passwordConfirmation: false }));
            return true;
        } else {
            setFormError((prevState) => ({ ...prevState, passwordConfirmation: true }));
            return false;
        }
    }
    const token = useContext(GlobalContextState).token;

    async function handleSubmit(event) {
        event.preventDefault();
        let valForm = true;
        setLoadingSubmit(true);

        // Validate form
        let password = formData.password;
        let passwordConfirmation = formData.passwordConfirmation;

        if (password === '' || passwordConfirmation === '') {
            valForm = false;
            setFormError((prevState) => ({ ...prevState, password: true }));
        } else {
            setFormError((prevState) => ({ ...prevState, password: false }));
            valForm = validatePassword(password, passwordConfirmation);
        }

        if (valForm) {
            try {
                let myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", `Bearer ${token}`);

                if (password !== '') {
                    setFormData({ ...formData, password: password });
                } else {
                    delete formData.password;
                }

                var requestOptions = {
                    method: 'PUT',
                    headers: myHeaders,
                    body: JSON.stringify(formData)
                };

                fetch(userUrl, requestOptions)
                    .then((response) => {
                        return response.json();
                    })
                    .then(function (data) {
                        setLoadingSubmit(false);
                        if (data && data.hasOwnProperty('error')) {
                            let message = data.error.message;
                            setStatus({ type: 'error', message: message });
                        } else {
                            let message = 'Your information has been updated.';
                            setStatus({ type: 'success', message: message });
                            dispatch({ type: "LOGIN", payload: { jwt: token, user: data } });
                        }
                    })
                    .catch((error) => {
                        setStatus({ type: 'error', message: error });
                        setLoadingSubmit(false);
                    });

            } catch (error) {
                setStatus({ type: 'error', message: error });
                setLoadingSubmit(false);
            }
        } else {
            setLoadingSubmit(false);
        }

    };
    return (
        <>
            <BaseHeaderLayout primaryAction={<Button onClick={handleSubmit} startIcon={<Check />} loading={loadingSubmit && true} >Save</Button>} title="Update Password" as="h2" />

            <ContentLayout>
                <Box color="neutral800" padding={6} background="neutral0">
                    {status?.type === "success" && (
                        <div>
                            <Alert className="mb-6" closeLabel="Close" title="Success" variant="success">
                                {status?.message}
                            </Alert>
                        </div>
                    )}
                    {status?.type === "error" && (
                        <div>
                            <Alert className="mb-6" closeLabel="Close" title="Error" variant="danger">
                                {status?.message}
                            </Alert>
                        </div>
                    )}
                    <Grid gap={5}>
                        <GridItem col={6}>
                            <TextInput type="password"
                                label="Password"
                                name="password"
                                onChange={handleInputChange}
                                error={formError.password && "Please enter your password."} />
                        </GridItem>
                        <GridItem col={6}>
                            <TextInput type="password"
                                label="Password Confirmation"
                                name="passwordConfirmation"
                                onChange={handleInputChange}
                                error={formError.passwordConfirmation && "Please make sure your passwords match."} />
                        </GridItem>
                    </Grid>
                </Box>
            </ContentLayout>
        </>
    );
}
