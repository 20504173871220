import { Switch, Route } from "react-router-dom";
import React from "react";
import SideNav from "../components/SideNav/SideNav";
import { Layout, Box } from "@strapi/design-system";
import DashboardView from '../components/Dashboard/DashboardView';
import { Helmet } from "react-helmet";

export default function Dashboard() {
  return (
    <Box background="neutral100">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trueclaim App - Dashboard</title>
      </Helmet>
      <Layout sideNav={<SideNav />}>
        <main className="flex-1">
          <Switch>
            <Route exact path="/dashboard" render={() => <DashboardView />} />
          </Switch>
        </main>
      </Layout>
    </Box>
  );
}
