import React, { useState, useEffect } from 'react';
import { Grid, GridItem } from '@strapi/design-system';
import Modal from 'react-modal';


const YouTubeVideos = ( { playListId, apiKey } ) => {
    const [videos, setVideos] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentVideoId, setCurrentVideoId] = useState(null);

    const openModal = (videoId) => {
        setCurrentVideoId(videoId);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setCurrentVideoId(null);
    };
    
    const fecthPlaylistUrl = `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playListId}&maxResults=25&key=${apiKey}`;
    
    useEffect(() => {
        const fetchPlaylist = async () => {
        try {
            const response = await fetch(fecthPlaylistUrl);
            if(response.ok) {
            const data = await response.json();
            console.log(data.items);
            setVideos(data.items);
            }
        } catch (error) {
            console.error(error);
        }
        };
    
        fetchPlaylist();
    }, [playListId]);
    
    return (
        <div className='video-list-container'>
        {videos && (
            <>
                <Grid columns={24} gap={5}>
                    {videos.map((video, index) => (
                        <>
                        {video.snippet.title !== 'Private video' && video.snippet.title !== 'Deleted video' && (
                            <GridItem col={2} key={index} onClick={() => openModal(video.snippet.resourceId.videoId)}>
                            <div className="video-container">
                                <div className="video-iframe">
                                    <img
                                    src={video.snippet.thumbnails.medium.url}
                                    alt={video.snippet.title}
                                    />
                                </div>
                                <div className="video-title">
                                    <h3>{video.snippet.title}</h3>
                                </div>
                            </div>
                            </GridItem>
                        )}
                        </>
                    ))}
                </Grid>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                    },
                    }}
                >
                    <button onClick={closeModal}>Close</button>
                    {currentVideoId && (
                    <div>
                        <iframe
                        width="1024"
                        height="768"
                        src={`https://www.youtube.com/embed/${currentVideoId}?rel=0`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="YouTube Video"
                        ></iframe>
                    </div>
                    )}
                </Modal>
            </>
        )}
        </div>
    );
};

export default YouTubeVideos;