import React from "react";

const PoiSvg = (props) => {

  const {
    imagesOpline,
    handlePartThumbMaskingMask,
    updatePoiSelectedImage
  } = props;

  return (
    <>
      <div className="images-opline">
        {imagesOpline.map((image, index) => (
          <div className="images-opline-item" 
            onClick={() => updatePoiSelectedImage(index)} 
            key={index}>
            <div className="sqrimg" id={`step4_main_container_thumb_${index}`}>
              <img
                className="step4_main_img_thumb"
                src={image['url']}
                data-index={index}
                data-key={image['name']}
                onLoad={() => handlePartThumbMaskingMask(image['name'])}
                alt=""
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );

};

export default PoiSvg;