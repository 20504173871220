import UpdateProfile from "../components/Account/UpdateProfile";
import Menu from "../components/Account/Menu";
import { Layout} from "@strapi/design-system";

export default function AccountSettingsUpdateProfile() {
  const expandedSection = 'acc-3';

  return (
    <div>
      <Layout
        sideNav={
          <Menu 
            expandedSection={expandedSection}
          />
        }
      >
        <UpdateProfile />
      </Layout>
    </div>
  );
}
