import * as React from 'react';

export default function ActiveAccount() {
    return (
      <div>
        <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 mb-10">
          <div className="flex-1 min-w-0">
            <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Active account</h1>
          </div>
        </div>
        <stripe-pricing-table pricing-table-id="prctbl_1OoXJV4UYAPuv1sthATqeW0b"
        publishable-key="pk_live_gLpMCRPA72YAnMi0TkjFYKoW">
        </stripe-pricing-table>
      </div>
    );
  }
  