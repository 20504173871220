import React from "react";
import { BarChart } from '@mui/x-charts/BarChart';

export default function Review({ starsReview }) {
  return (
    <>
      <div className="responsive-chart">
        <BarChart
          xAxis={[{ scaleType: 'band', data: ['1 star', '2 stars', '3 stars', '4 stars', '5 stars'], fill: '#ffffff' }]}
          series={[{ data: starsReview }]}
          width={500}
          height={300}
          barLabel="value"
        />
      </div>
    </>
  );
}
