
import React, {useContext, useEffect, useState} from "react";
import { ContentLayout, BaseHeaderLayout } from "@strapi/design-system";
import { GlobalContextState } from "../../context/globalContext";
import Review from './Chart/Review';
import { baseUrl } from "../../config";
import qs from 'qs';

export default function DashboardView() {
  const user = useContext(GlobalContextState).user;
  const token = useContext(GlobalContextState).token;
  const [starsReview, setStarsReview] = useState([0, 0, 0, 0, 0]);

  useEffect(() => {
    const initFetchData = async () => {
      await fetchFormData();
    };

    initFetchData();
  }, []);

  const fetchFormData = async () => {
    const reviewUrl = `${baseUrl}/api/chat-sessions`;

    try {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);
      
      const query = qs.stringify({
        filters: {
          reviewCount: {
            $gt: 0,
          },
        },
      }, {
        encodeValuesOnly: true,
      });

      const fetchUrl = `${reviewUrl}?${query}`;
      const response = await fetch(fetchUrl, {
        method: 'GET',
        headers: myHeaders,
      });

      if (response.ok) {
        let rate1 = 0;
        let rate2 = 0;
        let rate3 = 0;
        let rate4 = 0;
        let rate5 = 0;
        // starsReview format: [1, 2, 3, 4, 5]
        const data = await response.json();
        const allReviews = data.data;
        allReviews.forEach(review => {
          if(review.attributes.reviewCount === 1){
            rate1++;
          } else if(review.attributes.reviewCount === 2){
            rate2++;
          } else if(review.attributes.reviewCount === 3){
            rate3++;
          } else if(review.attributes.reviewCount === 4){
            rate4++;
          } else if(review.attributes.reviewCount === 5){
            rate5++;
          }
        });
        setStarsReview([rate1, rate2, rate3, rate4, rate5]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <BaseHeaderLayout
        title="Dashboard"
        as="h2"
      />
      <ContentLayout>
        {user.superAdmin && (
          <>
            <iframe src="https://demo.trueclaim.ai/" width="100%" height="2800"></iframe>
            
            <div className="flex-row md:flex">
              <div className="mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 md:mb-0">
                <h1 className="mb-2 max-w-full text-3xl font-bold tracking-tight text-gray-900 dark:text-white">Reviews</h1>
                <Review starsReview={starsReview} />
              </div>
              <div className="mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 md:mb-0">
                
              </div>
              <div className="mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 md:mb-0">
                
              </div>
            </div>
          </>
        )}
      </ContentLayout>
    </div>
  );
}