import Config from "../components/Account/Config";
import Menu from "../components/Account/Menu";
import { Layout} from "@strapi/design-system";

export default function AccountSettings() {
  const expandedSection = 'acc-1';

  return (
    <div>
      <Layout
        sideNav={
          <Menu 
            expandedSection={expandedSection}
          />
        }
      >
        <Config />
      </Layout>
    </div>
  );
}
