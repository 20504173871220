import React from "react";

const PrivacyPolicy = () => {
    return (
        <>
          <div className="bg-gray-100 py-6 border-b border-neutral-600	n	border-solid flex flex-col items-center justify-center">
            <img className="max-h-[40px]" src="/assets/images/logo-primary.png" alt="Strapi logo" />
          </div>
          <div className="bg-gray-100 text-gray-800 py-12 leading-7 h-full">
            <div className="container mx-auto p-6">
                <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
                    <p>Welcome to Trueclaim.ai. We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and protect the information you provide through our website, in compliance with applicable privacy laws in Quebec, Canada.</p>
                </section>
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Information We Collect</h2>
                    <h3 className="text-xl font-semibold mb-2">Personal Information</h3>
                    <p>When you visit our website, we may collect personal information that you voluntarily provide, such as:</p>
                    <ul className="list-disc list-inside ml-4">
                        <li>Your name</li>
                        <li>Your email address</li>
                        <li>Your phone number</li>
                        <li>Any other information you choose to provide through our contact forms or newsletter sign-ups</li>
                    </ul>
                    <h3 className="text-xl font-semibold mb-2 mt-4">Browsing Data</h3>
                    <p>We also automatically collect certain information when you browse our site, such as:</p>
                    <ul className="list-disc list-inside ml-4">
                        <li>Your IP address</li>
                        <li>The type of browser you use</li>
                        <li>The pages you visit</li>
                        <li>The time spent on our site</li>
                        <li>The links you click</li>
                    </ul>
                </section>
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">How We Use Your Information</h2>
                    <p>We use the information collected for the following purposes:</p>
                    <ul className="list-disc list-inside ml-4">
                        <li><span className="font-semibold">Improving Our Website:</span> To analyze how our website is used and improve its content and functionality.</li>
                        <li><span className="font-semibold">Communication:</span> To respond to your inquiries, questions, or comments.</li>
                        <li><span className="font-semibold">Marketing:</span> To send you information about our products, services, special offers, and events if you have consented to receive such communications.</li>
                    </ul>
                </section>
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Sharing Your Information</h2>
                    <p>We do not sell, trade, or rent your personal information to third parties. We may share your information with trusted third parties who assist us in operating our website, conducting our business, or serving you, as long as these parties agree to keep this information confidential.</p>
                </section>
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Information Security</h2>
                    <p>We implement various security measures to protect your personal information against unauthorized access, disclosure, alteration, or destruction.</p>
                </section>
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Your Rights</h2>
                    <p>In accordance with the Act Respecting the Protection of Personal Information in the Private Sector (LPRPSP), you have the right to:</p>
                    <ul className="list-disc list-inside ml-4">
                        <li><span className="font-semibold">Access Your Information:</span> Request access to the personal information we hold about you.</li>
                        <li><span className="font-semibold">Correct Your Information:</span> Request the correction of any inaccurate or incomplete information.</li>
                        <li><span className="font-semibold">Delete Your Information:</span> Request the deletion of your personal information, subject to certain legal obligations.</li>
                    </ul>
                    <p>To exercise these rights, please contact us using the information provided below.</p>
                </section>
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Changes to This Privacy Policy</h2>
                    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Your continued use of our site after the posting of changes constitutes your acceptance of those changes.</p>
                </section>
                <section>
                    <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
                    <p>If you have any questions or concerns regarding this Privacy Policy or our data practices, please contact us at:</p>
                    <p className="font-semibold">Trueclaim.ai</p>
                    <p>Email: <a href="mailto:contact@trueclaim.ai" className="text-blue-500 hover:underline">contact@trueclaim.ai</a></p>
                </section>
            </div>
        </div>
        </>
    );
}

export default PrivacyPolicy;