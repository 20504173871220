import React, { useState, useContext, useEffect } from "react";
import { GlobalContextState } from "../../context/globalContext";
import { baseUrl } from "../../config";
import {
  Alert,
  Button,
  BaseHeaderLayout,
  Typography,
  RadioGroup,
  Radio,
  ContentLayout,
  Box,
  Tooltip,
  Checkbox,
  Switch,
  Grid,
  GridItem,
  TextInput,
  Link,
  Loader,
  Status,
  MultiSelect,
  MultiSelectOption,
  Icon,
  LinkButton
} from "@strapi/design-system";
import { Check, PicturePlus, Information } from "@strapi/icons";
import { LoadingSpinner } from "../ui/loading-spinner";
import { FileUploader } from "react-drag-drop-files";
import { Table, Thead, Tbody, Tr, Td, Th } from "@strapi/design-system";
import { Tabs } from "@strapi/design-system";
import { Field, useField } from "@strapi/design-system";
import { Flex } from "@strapi/design-system";
import Input from "../../styled/base/Input/Input";
import {
  PhoneInput,
  defaultCountries,
  parseCountry,
} from 'react-international-phone';
import 'react-international-phone/style.css';
const fileTypes = ["PDF", "XML"];

export default function Create() {
  const token = useContext(GlobalContextState).token;
  const user = useContext(GlobalContextState).user;
  const uP = user.subscriptionPlan;

  const createUrl = `${baseUrl}/api/tc-app/create-claim`;
  const getPdfDataUrl = `${baseUrl}/api/trueclaim/pdf-to-lines`;
  const getBmsDataUrl = `${baseUrl}/api/trueclaim/bms-to-lines`;
  const getVinUrl = `${baseUrl}/api/trueclaim/vin-from-img`;
  const [formData, setFormData] = useState({
    lastname: "",
    vin: "",
    file: null,
    extractedData: null,
    phone: "",
    email: "",
    firstname: "",
  });
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [createdClaimId, setCreatedClaimId] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingBms, setLoadingBms] = useState(false);
  const [loadingVin, setLoadingVin] = useState(false);
  const [disableLastName, setDisableLastName] = useState(false);
  const [disableVin, setDisableVin] = useState(false);
  const [lines, setLines] = useState([]);
  const [createMode, setCreateMode] = useState("pdf");
  const [vinError, setVinError] = useState(false);
  const [vinErrorText, setVinErrorText] = useState("");
  const [disableCreate, setDisableCreate] = useState(true);

  const [selected, setSelected] = useState();
  const [serviceSetting, setServiceSetting] = useState({
    rev: false,
    adas: true,
  });

  useEffect(() => {
    if(uP === "PreEst") {
      setCreateMode("manual");
    }
  }, [uP]);

  function updateServiceSetting(index, value) {
    setServiceSetting({ ...serviceSetting, [index]: value });
  }

  const [exportSelected, setExportSelected] = useState();
  const [exportSetting, setExportSetting] = useState({
    mitchell: false,
    audatex: false,
  });

  function updateExportSetting(index, value) {
    setExportSetting({ ...exportSetting, [index]: value });
  }

  function handleInputChange(event) {
    const { name, value } = event.target;
    if (value != "") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: "" });
    }
    if(createdClaimId) {
      setCreatedClaimId(null);
    }
  }

  const handlePdfChange = (file) => {
    console.log("File change", file);
    let parseEndpoint = getPdfDataUrl;
    if(file.type.includes("pdf")) {
      parseEndpoint = getPdfDataUrl;
    } else if(file.type.includes("xml")) {
      parseEndpoint = getBmsDataUrl;
    }
    setFormData({ ...formData, file: file });
    setLoadingPdf(true);
    if(createdClaimId) {
      setCreatedClaimId(null);
    }
    try {
      const formData = new FormData();
      formData.append("file", file);
      fetch(parseEndpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("PDF data", data);
          // Set the data to the form
          const { vin, name } = data;
          // Get last name from name
          const names = name.split(" ");
          const lastname = names[names.length - 1];
          setFormData({
            ...formData,
            vin: vin,
            lastname: lastname,
            file: file,
            extractedData: data,
          });
          setLines(data.lines);
          setLoadingPdf(false);
          setDisableLastName(true);
          setDisableVin(true);
        });
    } catch (error) {
      console.error(error);
      setLoadingPdf(false);
    }
  };

  const handleVinImageChange = (event) => {
    const file = event.target.files[0];
    setLoadingVin(true);
    if(createdClaimId) {
      setCreatedClaimId(null);
    }
    try {
      const formData = new FormData();
      formData.append("file", file);
      fetch(getVinUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("VIN", data);
          if (data.vin) {
            if (data.vin === "0") {
              setVinError(true);
              setVinErrorText("Could not extract VIN from the image");
            } else {
              setFormData({
                ...formData,
                vin: data.vin,
              });
            }
          }
          setLoadingVin(false);
        });
    } catch (error) {
      console.error(error);
      setLoadingVin(false);
    }
  };

  const handleVinInputBlur = () => {
    if (formData.vin.length !== 17) {
      setVinError(true);
      setVinErrorText("Please enter a valid VIN");
    } else {
      setVinError(false);
    }
  };

  const dropzoneHtml = (
    <div className="mt-4 flex grow flex-col items-center justify-center">
      <label
        htmlFor="dropzone-file"
        className="dark:hover:bg-bray-800 flex h-32 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
      >
        <div className="flex flex-col items-center justify-center p-5 text-center">
          {loadingPdf && (
            <div className="absolute bg-gray-50 dark:bg-gray-700 bg-opacity-50 dark:bg-opacity-50 h-full w-full flex items-center justify-center rounded-lg">
              <Loader className="mr-2"></Loader>
            </div>
          )}
          <svg
            aria-hidden="true"
            className="mb-3 h-10 w-10 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            ></path>
          </svg>
          {formData.file ? (
            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <span className="font-semibold">{formData.file.name}</span>
            </p>
          ) : (
            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <span className="font-semibold">
                Click to upload or drop file here to create new report
              </span>
            </p>
          )}
          <p className="text-xs text-gray-500 dark:text-gray-400">
            PDF or BMS (MAX. 20 MB)
          </p>
        </div>
        <input
          id="dropzone-file"
          className="hidden"
          max-size="20MB"
          accept=".xml,.pdf"
          type="file"
        />
      </label>
    </div>
  );

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    try {
      setCreatedClaimId(null);
      setHasError(false);

      const form = new FormData();
      form.append("file", formData.file);
      form.append("lastname", formData.lastname);
      form.append("vin", formData.vin);
      form.append("phone", formData.phone);
      form.append("email", formData.email);
      form.append("firstname", formData.firstname);
      form.append("extractedData", JSON.stringify(formData.extractedData));
      form.append("mode", createMode);
      form.append("services", JSON.stringify(serviceSetting));
      form.append("exports", JSON.stringify(exportSetting));

      // Send POST request to update configuration data
      const response = await fetch(createUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: form,
      });

      const data = await response.json();
      console.log(data);
      if (data.status === "success") {
        setCreatedClaimId(data.data.claimId);
        // Clear the form
        setFormData({
          lastname: "",
          phone: "",
          email: "",
          firstname: "",
          vin: "",
          file: null,
          extractedData: null,
        });
        setServiceSetting({
          rev: false,
          adas: true,
        });
        setExportSetting({
          mitchell: false,
          audatex: false,
        });
        setDisableLastName(false);
        setDisableVin(false);
      } else {
        setHasError(true);
      }
    } catch (error) {
      console.error(error);
      setHasError(true);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const handleClear = () => {
    setFormData({
      lastname: "",
      vin: "",
      file: null,
      extractedData: null,
      phone: "",
      email: "",
      firstname: "",
    });
    setServiceSetting({
      rev: false,
      adas: true,
    });
    setExportSetting({
      mitchell: false,
      audatex: false,
    });
    setDisableLastName(false);
    setDisableVin(false);
    setCreatedClaimId(null);
    setLines([]);
  };

  return (
    <div>
      <div className="base-header-layout mobile-header-layout">
        <BaseHeaderLayout
          title="New Claim"
          as="h2"
          primaryAction={
            <div className="flex">
              <Button
                variant="secondary"
                className="mr-2"
                onClick={handleClear}
              >
                Clear
              </Button>
              <Button
                onClick={handleSubmit}
                loading={loadingSubmit}
                startIcon={<Check />}
                disabled={
                  formData.vin.length !== 17 || formData.lastname === ""
                }
              >
                Create
              </Button>
            </div>
          }
        />
      </div>
      <div className="base-header-layout">
        <ContentLayout>
          {createdClaimId && (
            <Box marginBottom={6}>
              <Alert className="mt-6 align-center" variant="success" closeLabel="Close" onClose={() => setCreatedClaimId(null)}>
                Your claim has been created
                <br />
                <br />
                <LinkButton
                  variant="primary"
                  href={`https://chat.trueclaim.ai/?claimId=${createdClaimId}`}
                  isExternal
                  className="mr-2"
                  size="S"
                >
                  Start Damage Info
                </LinkButton>
                <LinkButton
                  variant="secondary"
                  href={`/claims/claim-details/${createdClaimId}`}
                  size="S"
                  isExternal
                >
                  View Claim Details
                </LinkButton>
              </Alert>
            </Box>
          )}
          {loadingSubmit && (
            <Box marginBottom={6}>
              <Status className="mt-6" showBullet={true}>
                <Loader small className="mr-2"></Loader> Creating claim...
              </Status>
            </Box>
          )}
          {hasError && (
            <Box marginBottom={6}>
              <Alert className="mt-6" closeLabel="Close" variant="danger">
                An error occurred while creating the claim. Please try again.
              </Alert>
            </Box>
          )}
          <Box color="neutral800" padding={6} background="neutral0" marginBottom={6}>
            <Grid className="mobile-grid-12" gap={5}>
              <GridItem col={6}>
                <Typography className="mb-2 block" variant="sigma">
                  Select Create Mode
                </Typography>
                <RadioGroup
                  labelledBy="create-mode-selection"
                  onChange={(e) => setCreateMode(e.target.value)}
                  value={createMode}
                  name="createModeSelection"
                >
                  <div className="mt-4">
                    <Radio value="pdf" disabled={uP === "PreEst" ? true : false}>Upload existing estimate</Radio>
                  </div>
                  <div className="mt-4">
                    <Radio value="manual">Start new estimate</Radio>
                  </div>
                </RadioGroup>
              </GridItem>
            </Grid>
          </Box>
          <Box color="neutral800" padding={6} background="neutral0">            
            {createMode === "pdf" && (
              <>
                <Box>
                  <Grid className="mobile-grid-12 mb-4" gap={5}>
                    <GridItem col={6}>
                      <Typography
                        className="mb-2 block"
                        variant="sigma"
                        id="profile-selection"
                      >
                        Create in
                      </Typography>
                      <RadioGroup
                        labelledBy="profile-selection"
                        onChange={(e) => setExportSelected(e.target.value)}
                        value={exportSelected}
                        name="profileSelection"
                      >
                        <div className="flex">
                          <div>
                            <Checkbox
                              value={exportSetting["mitchell"]}
                              disabled={uP === "PostEstimate" ? true : false}
                              onChange={(e) => {
                                updateExportSetting("mitchell", e.target.checked);
                              }}
                            >
                              Mitchell
                            </Checkbox>
                          </div>
                          <div className="ml-4">
                            <Checkbox
                              value={exportSetting["audatex"]}
                              disabled={uP === "PostEstimate" ? true : false}
                              onChange={(e) => {
                                updateExportSetting("audatex", e.target.checked);
                              }}
                            >
                              Audatex
                            </Checkbox>
                          </div>
                        </div>
                      </RadioGroup>
                    </GridItem>
                    <GridItem col={6}>
                      <Typography
                        className="mb-4 block"
                        variant="sigma"
                        id="profile-selection"
                      >
                        Services
                      </Typography>
                      <RadioGroup
                        labelledBy="profile-selection"
                        onChange={(e) => setSelected(e.target.value)}
                        value={selected}
                        name="profileSelection"
                      >
                        <div className="flex">
                          <div>
                            <Checkbox
                              value={serviceSetting["adas"]}
                              disabled={uP === "PreEst" ? true : false}
                              onChange={(e) => {
                                updateServiceSetting("adas", e.target.checked);
                              }}
                            >
                              ADAS
                            </Checkbox>
                          </div>
                          <div className="ml-4">
                            <Checkbox
                              value={serviceSetting["rev"]}
                              disabled={uP === "PreEst" ? true : false}
                              onChange={(e) => {
                                updateServiceSetting("rev", e.target.checked);
                              }}
                            >
                              REV
                            </Checkbox>
                          </div>
                        </div>
                      </RadioGroup>
                    </GridItem>
                  </Grid>
                  <Box marginBottom={2}>
                    <Typography variant="pi" fontWeight="bold">
                      Upload PDF or BMS
                    </Typography>
                  </Box>
                  <FileUploader
                    handleChange={handlePdfChange}
                    name="file"
                    types={fileTypes}
                    children={dropzoneHtml}
                  />
                </Box>
                {formData.extractedData && lines.length > 0 && (
                  <Box className="mt-6">
                    <Box padding={6}>
                      <Box className="mt-2">
                        <Typography variant="OMEGA" className="font-bold">
                          Name:&nbsp;
                        </Typography>
                        <Typography variant="OMEGA">
                          {formData.extractedData.name}
                        </Typography>
                      </Box>
                      <Box className="mt-2">
                        <Typography variant="OMEGA" className="font-bold">
                          Vehicle:&nbsp;
                        </Typography>
                        <Typography variant="OMEGA">
                          {formData.extractedData.vehicle_name}
                        </Typography>
                      </Box>
                      <Box className="mt-2">
                        <Typography variant="OMEGA" className="font-bold">
                          VIN:&nbsp;
                        </Typography>
                        <Typography variant="OMEGA">
                          {formData.extractedData.vin}
                        </Typography>
                      </Box>
                      <Box className="mt-2">
                        <Typography variant="OMEGA" className="font-bold">
                          Mileage:&nbsp;
                        </Typography>
                        <Typography variant="OMEGA">
                          {formData.extractedData.odometer || "N/A"}
                        </Typography>
                      </Box>
                      <Box className="mt-2">
                        <Typography variant="OMEGA" className="font-bold">
                          Insurance:&nbsp;
                        </Typography>
                        <Typography variant="OMEGA">
                          {formData.extractedData.insurance_company || "N/A"}
                        </Typography>
                      </Box>
                    </Box>
                    <Table colCount={5} rowCount={lines.length}>
                      <Thead>
                        <Tr>
                          <Th>
                            <Typography variant="sigma">No</Typography>
                          </Th>
                          <Th>
                            <Typography variant="sigma">DB Ref</Typography>
                          </Th>
                          <Th>
                            <Typography variant="sigma">Header</Typography>
                          </Th>
                          <Th>
                            <Typography variant="sigma">Description</Typography>
                          </Th>
                          <Th>
                            <Typography variant="sigma">Operation</Typography>
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {lines.map((line, index) => (
                          <Tr key={index}>
                            <Td>
                              <Typography variant="omega">{line.no}</Typography>
                            </Td>
                            <Td>
                              <Typography variant="omega">
                                {line.dbRef}
                              </Typography>
                            </Td>
                            <Td>
                              <Typography variant="omega">
                                {line.header}
                              </Typography>
                            </Td>
                            <Td>
                              <Typography variant="omega">
                                {line.description}
                              </Typography>
                            </Td>
                            <Td>
                              <Typography variant="omega">
                                {line.operation}
                              </Typography>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                )}
              </>
            )}
            {createMode === "manual" && (
              <>
                <Grid className="mobile-grid-12 mb-4" gap={5}>
                  <GridItem col={6}>
                    <Typography
                      className="mb-2 block"
                      variant="sigma"
                      id="profile-selection"
                    >
                      Create in
                    </Typography>
                    <RadioGroup
                      labelledBy="profile-selection"
                      onChange={(e) => setExportSelected(e.target.value)}
                      value={exportSelected}
                      name="profileSelection"
                    >
                      <div className="flex">
                        <div>
                          <Checkbox
                            value={exportSetting["mitchell"]}
                            disabled={uP === "PostEstimate" ? true : false}
                            onChange={(e) => {
                              updateExportSetting("mitchell", e.target.checked);
                            }}
                          >
                            Mitchell
                          </Checkbox>
                        </div>
                        <div className="ml-4">
                          <Checkbox
                            value={exportSetting["audatex"]}
                            disabled={uP === "PostEstimate" ? true : false}
                            onChange={(e) => {
                              updateExportSetting("audatex", e.target.checked);
                            }}
                          >
                            Audatex
                          </Checkbox>
                        </div>
                      </div>
                    </RadioGroup>
                  </GridItem>
                  <GridItem col={6}>
                    <Typography
                      className="mb-4 block"
                      variant="sigma"
                      id="profile-selection"
                    >
                      Services
                    </Typography>
                    <RadioGroup
                      labelledBy="profile-selection"
                      onChange={(e) => setSelected(e.target.value)}
                      value={selected}
                      name="profileSelection"
                    >
                      <div className="flex">
                        <div>
                          <Checkbox
                            value={serviceSetting["adas"]}
                            disabled={uP === "PreEst" ? true : false}
                            onChange={(e) => {
                              updateServiceSetting("adas", e.target.checked);
                            }}
                          >
                            ADAS
                          </Checkbox>
                        </div>
                        <div className="ml-4">
                          <Checkbox
                            value={serviceSetting["rev"]}
                            disabled={uP === "PreEst" ? true : false}
                            onChange={(e) => {
                              updateServiceSetting("rev", e.target.checked);
                            }}
                          >
                            REV
                          </Checkbox>
                        </div>
                      </div>
                    </RadioGroup>
                  </GridItem>
                </Grid>
                <Grid className="mobile-grid-12 mb-4" gap={5}>
                  <GridItem col={6}>
                    <TextInput
                      label="First Name"
                      name="firstname"
                      placeholder="First Name"
                      value={formData.firstname}
                      onChange={handleInputChange}
                      disabled={loadingSubmit}
                    />
                  </GridItem>
                  <GridItem col={6}>
                    <TextInput
                      label="Last Name"
                      name="lastname"
                      placeholder="Last Name"
                      value={formData.lastname}
                      onChange={handleInputChange}
                      disabled={loadingSubmit || disableLastName}
                      required={true}
                    />
                  </GridItem>
                </Grid>
                <Grid className="mobile-grid-12 mb-4" gap={5}>
                  <GridItem col={6}>
                    <TextInput
                      label="Email"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      disabled={loadingSubmit}
                    />
                  </GridItem>
                  <GridItem col={6}>
                    <Typography
                      className="mb-1 block"
                      variant="pi"
                      fontWeight="bold"
                    >
                      Phone
                    </Typography>
                    <PhoneInput
                      defaultCountry="ca"
                      value={formData.phone}
                      onChange={(phone) => handleInputChange({ target: { name: 'phone', value: phone } })}
                      disabled={loadingSubmit}
                    />
                  </GridItem>
                </Grid>
                <Grid className="mobile-grid-12 mb-4" gap={5}>
                  <GridItem col={6}>
                    <Box className="w-full relative">
                      <TextInput
                        label="VIN"
                        name="vin"
                        placeholder="VIN"
                        value={formData.vin}
                        onChange={handleInputChange}
                        onBlur={handleVinInputBlur}
                        onFocus={() => setVinError(false)}
                        disabled={loadingSubmit || disableVin || loadingVin}
                        hint="Manually enter the VIN or upload an image to extract the VIN"
                        error={vinError && vinErrorText}
                        required={true}
                      />
                      <div className="absolute right-1 bottom-6">
                        <Button
                          variant="secondary"
                          className="height-10"
                          disabled={loadingSubmit || disableVin || loadingVin}
                          loading={loadingVin}
                          startIcon={<PicturePlus />}
                          onClick={() => {
                            document
                              .querySelector('input[name="vin_image"]')
                              .click();
                          }}
                        >
                          Upload
                        </Button>
                      </div>
                      <input
                        type="file"
                        className="hidden"
                        name="vin_image"
                        onChange={handleVinImageChange}
                      />
                    </Box>
                  </GridItem>
                </Grid>
              </>
            )}
          </Box>
        </ContentLayout>
      </div>
    </div>
  );
}
