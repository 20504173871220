import React, { useState, useEffect, useContext } from "react";
import { NavLink } from 'react-router-dom';
import { GlobalContextState } from "../../context/globalContext";
import {
  SubNav,
  SubNavHeader,
  SubNavLink
} from "@strapi/design-system/v2";
import { Accordion, AccordionToggle, AccordionContent, AccordionGroup } from '@strapi/design-system';

export default function Menu({ expandedSection }) {
  const [expandedID, setExpandedID] = useState('');
  const user = useContext(GlobalContextState).user;
  const userSubscription = user.subscription ? "active" : "inactive";

  useEffect(() => {
    setExpandedID(expandedSection);
  }, [expandedSection]);

  const handleToggle = (id) => () => {
    setExpandedID(s => s === id ? null : id);
  };

  return (
    <>
      <SubNav className="accordion-group-nav" ariaLabel="Settings sub nav">
        <SubNavHeader label="Settings" />
        <AccordionGroup>
        {userSubscription === "active" && (
          <>
            <div className="custom-nav-link">
              <NavLink as={NavLink} to="/settings/configurations">
                Configuration
              </NavLink>
            </div>
            <Accordion expanded={expandedID === 'acc-2'} onToggle={handleToggle('acc-2')} id="acc-2" size="S">
              <AccordionToggle title="Integrations" togglePosition="right" />
              <AccordionContent>
                <SubNavLink as={NavLink} to="/settings/config-mitchell">
                  Mitchell Connection
                </SubNavLink>
                <SubNavLink as={NavLink} to="/settings/config-audatex">
                  Audatex
                </SubNavLink>
                <SubNavLink as={NavLink} to="/settings/config-ccc">
                  CCC
                </SubNavLink>
                <SubNavLink as={NavLink} to="/settings/config-parts-trader">
                  PartsTrader
                </SubNavLink>
                <SubNavLink as={NavLink} to="/settings/config-progi-sync">
                  Progi Sync
                </SubNavLink>
                <SubNavLink as={NavLink} to="/settings/config-progi-parts">
                  Progi Parts
                </SubNavLink>
                <SubNavLink as={NavLink} to="/settings/config-repair-center">
                  RepairCenter
                </SubNavLink>
                <SubNavLink as={NavLink} to="/settings/config-alldata">
                  Alldata
                </SubNavLink>
              </AccordionContent>
            </Accordion>
          </>
        )}
          <Accordion expanded={expandedID === 'acc-3'} onToggle={handleToggle('acc-3')} id="acc-3" size="S">
            <AccordionToggle title="Account" togglePosition="right" />
            <AccordionContent>
              <SubNavLink as={NavLink} to="/settings/update-profile">
                Profile
              </SubNavLink>
              <SubNavLink as={NavLink} to="/settings/update-password">
                Update Password
              </SubNavLink>
            </AccordionContent>
          </Accordion>
          <Accordion expanded={expandedID === 'acc-4'} onToggle={handleToggle('acc-4')} id="acc-4" size="S">
            <AccordionToggle  title="Billing" togglePosition="right" />
            <AccordionContent>
              <SubNavLink as={NavLink} to="/settings/subscription">
                Subscription
              </SubNavLink>
              <SubNavLink>Account Statement</SubNavLink>
            </AccordionContent>
          </Accordion>
        </AccordionGroup>
      </SubNav>
    </>
  );
}