const poiParts = {
    'Grille': 'Front',
    'bumper': 'Rear Rear_Left Rear_Right',
    'rear_bumper': 'Rear Rear_Left Rear_Right',
    'bonnet': 'Front',
    'front_bumper': 'Front Front_Left Front_Right',
    'door_front_left': 'Front_Left Left',
    'door_front_right': 'Front_Right Right',
    'door_rear_left': 'Rear_Left Left',
    'door_rear_right': 'Rear_Right Right',
    'fender_front_left': 'Front_Left Left',
    'fender_front_right': 'Front_Right Right',
    'fender_rear_left': 'Rear_Left Left',
    'fender_rear_right': 'Rear_Right Right',
    'quarter_panel_rear_left': 'Rear_Left Left',
    'quarter_panel_rear_right': 'Rear_Right Right',
    'headlight_left': 'Front_Left Front',
    'headlight_right': 'Front_Right Front',
    'mirror_front_left': 'Front_Left Left',
    'mirror_front_right': 'Front_Right Right',
    'rear_windshield': 'Rear',
    'tail_light_left': 'Rear_Left Rear',
    'tail_light_right': 'Rear_Right Rear',
    'wheel_front_left': 'Front_Left Left',
    'wheel_front_right': 'Front_Right Right',
    'wheel_rear_left': 'Rear_Left Left',
    'wheel_rear_right': 'Rear_Right Right',
    'window_front_left': 'Front_Left Left',
    'window_front_right': 'Front_Right Right',
    'window_rear_left': 'Rear_Left Left',
    'window_rear_right': 'Rear_Right Right',
    'windshield': 'Front',
    'quarter_window_rear_left': 'Rear_Left Left',
    'quarter_window_rear_right': 'Rear_Right Right',
    'Door-Handle': 'Left Right',
    'FogLamps-Area': 'Front_Left Front_Right Front',
    'Rim': 'Front_Left Front_Right Rear_Left Rear_Right Left Right',
    'FuelTap': 'Left',
    'running_board_left': 'Left Front_Left Rear_Left',
    'running_board_right': 'Right Front_Right Rear_Right',
  };
  
export default poiParts;
  