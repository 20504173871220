import React, { useContext } from "react";
import { GlobalContextState } from "../../context/globalContext";
import { BaseHeaderLayout, ContentLayout, Alert, Button } from "@strapi/design-system";
import { Helmet } from "react-helmet";

export default function Subscription() {
  const user = useContext(GlobalContextState).user;
  const userSubscription = user.subscription ? "active" : "inactive";

  function redirectToChange() {
    window.location.href = "/settings/subscription-change";
  }

  return (
    <>
      <Helmet>
        <script
          src="https://js.stripe.com/v3/pricing-table.js"
          crossorigin="anonymous"
          async
        ></script>
      </Helmet>
      {userSubscription === "active" && (
      <div>
        <BaseHeaderLayout title="Subscription" as="h2" primaryAction={<Button onClick={redirectToChange}>Change Subscription</Button>} />
        <ContentLayout>
            <Alert className="mb-6" closeLabel="Close" variant="success">
              Your account is active
            </Alert>
            <ul>
              <li className="mb-2"><strong className="w-40 inline-block">Current Plan:</strong> {user.subscriptionPlan} Subscription</li>
              {user.subscriptionType && (
                <li><strong className="w-40 inline-block">Subscription Type:</strong> {user.subscriptionType}</li>
              )}
            </ul>
            <Alert className="mt-6 no-icon" closeLabel="Close" variant="danger">
              If you want change / cancel your subscription, please contact support.
            </Alert>
        </ContentLayout>
      </div>
      )}
      {userSubscription === "inactive" && (
      <div>
        <BaseHeaderLayout title="Subscription" as="h2" />
        <ContentLayout>
          <Alert className="mb-6 no-icon" closeLabel="Close">
            Your account is not active. Please choose a plan to activate your account
          </Alert>
          <div className="flex-justify-center">
          <stripe-pricing-table pricing-table-id="prctbl_1PLbRm4UYAPuv1stbw8AbjDX" publishable-key="pk_live_gLpMCRPA72YAnMi0TkjFYKoW">
          </stripe-pricing-table>
          </div>
        </ContentLayout>
      </div>
      )}
    </>
  );
}
