import Menu from "../components/Account/Menu";
import { Layout} from "@strapi/design-system";
import { BaseHeaderLayout, ContentLayout } from "@strapi/design-system";
import { Helmet } from "react-helmet";

export default function AccountSubscriptionChange() {
  const expandedSection = 'acc-4';

  return (
    <div>
      <Helmet>
        <script
          src="https://js.stripe.com/v3/pricing-table.js"
          crossorigin="anonymous"
          async
        ></script>
      </Helmet>
      <Layout
        sideNav={
          <Menu 
            expandedSection={expandedSection}
          />
        }
      >
        <div>
          <BaseHeaderLayout title="Change subscription" as="h2" />
          <ContentLayout>
            <div className="flex-justify-center">
            <stripe-pricing-table pricing-table-id="prctbl_1PLbRm4UYAPuv1stbw8AbjDX" publishable-key="pk_live_gLpMCRPA72YAnMi0TkjFYKoW">
            </stripe-pricing-table>
            </div>
          </ContentLayout>
        </div>
      </Layout>
    </div>
  );
}
