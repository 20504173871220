import React, { useState, useContext, useEffect } from "react";
import { GlobalContextState } from "../../context/globalContext";
import { GlobalContextDispatch } from "../../context/globalContext";
import { baseUrl } from "../../config";
import { BaseHeaderLayout, ContentLayout, Alert, Button, Box, GridItem, Grid } from "@strapi/design-system";
import { Check } from '@strapi/icons';
import Input from "../../styled/base/Input/Input";


const INITIAL_FORM_ERRORS = {
    mitchellUsername: false,
    mitchellPassword: false
};

export default function MitchellConection() {
  const user = useContext(GlobalContextState).user;
  const [loadingSubmit, setLoadingSubmit] = React.useState(false);
  const [formError, setFormError] = useState(INITIAL_FORM_ERRORS);
  const [mitchellDataUsername, setMitchellDataUsername] = useState('');

  useEffect(() => {
    if(user.mitchellUsername !== null){
        setMitchellDataUsername(user.mitchellUsername);
    }
    }, [user]);

  const [status, setStatus] = useState(undefined);
  const token = useContext(GlobalContextState).token;
  const userUrl = `${baseUrl}/api/users/${user.id}`;


  const dispatch = useContext(GlobalContextDispatch);

  function validateForm(mcUserName, mcPassword){
    let hasErrors = false;
    if(mcUserName === ''){
        hasErrors = true;
        setFormError((prevState) => ({ ...prevState, mitchellUsername: true }));
    }else {
        setFormError((prevState) => ({ ...prevState, mitchellUsername: false }));
    }
    if(mcPassword === ''){
        hasErrors = true;
        setFormError((prevState) => ({ ...prevState, mitchellPassword: true }));
    }else {
        setFormError((prevState) => ({ ...prevState, mitchellPassword: false }));
    }
    return hasErrors;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoadingSubmit(true);
    
    let mcUserName = document.getElementById("mitchellUsername").value;
    let mcPassword = document.getElementById("mitchellPassword").value;
    let hasErrors = validateForm(mcUserName, mcPassword);

    if(!hasErrors){
        let $apiUrl = 'https://api.apify.com/v2/actor-tasks/bouncy_ektara~mitchell-access-checker/run-sync?token=apify_api_sY3xrPgUhyqtmozFWfXVRmbULijEGc3ikVEw';
        let $data = {
            "username": mcUserName,
            "password": mcPassword
        };

        let $options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify($data)
        };

        const response = await fetch($apiUrl, $options);
        const responseJson = await response.json();
        
        if(responseJson.success === false){
            let message = 'The Mitchell username or password is incorrect.';
            setStatus({ type: 'error', message });
            setLoadingSubmit(false);
        }else {
            let message = 'Your account has been connected with Mitchell.';
            setStatus({ type: 'success', message });

            let myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", `Bearer ${token}`);

            let mitchellId = responseJson.bodyShop.id;
            let mitchellName = responseJson.bodyShop.name;
            let mitchellAccountNumber = responseJson.bodyShop.accountNumber;
            let mitchellOrgKey = responseJson.bodyShop.orgKey;
            let michellPrimaryUserName = responseJson.bodyShop.primaryUserName;

            let $dataSave = {
                "mitchellUsername": mcUserName,
                "mitchellPass": mcPassword,
                "mitchellId": mitchellId.toString(),
                "mitchellName": mitchellName,
                "mitchellAccountNumber": mitchellAccountNumber,
                "mitchellOrgKey": mitchellOrgKey,
                "michellPrimaryUserName": michellPrimaryUserName
            };

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify($dataSave)
            };

            fetch(userUrl, requestOptions)
            .then((response) => {
                return response.json();
            })
            .then(function(data) {
                if (data && data.hasOwnProperty('error')) {
                    let message = data.error.message;
                    setStatus({ type: 'error', message: message}); 
                }else {
                    let message = 'Your account has been connected with Mitchell.';
                    setStatus({ type: 'success', message: message});
                    dispatch({ type: "LOGIN", payload: { jwt: token, user: data } });
                }
            })
            .catch((error) => {
                setStatus({ type: 'error', error });
            });

            setLoadingSubmit(false);
        }
    }
  }

  return (
    <div>
        <BaseHeaderLayout primaryAction={<Button onClick={handleSubmit} startIcon={<Check />} loading={loadingSubmit && true} >Validate & Save</Button>}
                            title="Mitchell Connection" as="h2" />
        <ContentLayout>
            {user.mitchellUsername !== null && (
                <Alert className="mb-6" closeLabel="Close" variant="success">
                    Your account connected to Mitchell
                </Alert>
            )}
            {user.mitchellUsername == null && (
                <Alert className="mb-6" closeLabel="Close" variant="danger">
                    Your account is not connect to Mitchell
                </Alert>
            )}

            <Box color="neutral800" padding={6} background="neutral0">
                {status?.type === "success" && (
                <div>
                    <Alert className="mb-6" closeLabel="Close" title="Success" variant="success">
                        {status?.message}
                    </Alert>
                </div>
                )}
                {status?.type === "error" && (
                <div>
                    <Alert className="mb-6" closeLabel="Close" title="Error" variant="danger">
                        {status?.message}
                    </Alert>
                </div>
                )}
                <form className="form-label-style" autoComplete="off" role="presentation">
                    <Grid gap={5}>
                        <GridItem col={6}>
                            <Input
                                id="mitchellUsername"
                                className="mb-4"
                                name="mitchellUsername"
                                type="text"
                                label="Mitchell Username"
                                placeholder="Mitchell Username"
                                autoComplete="false"
                                role="presentation"
                                onChange={(e) => setMitchellDataUsername(e.target.value)}
                                value={mitchellDataUsername}
                                error={formError.mitchellPassword && "Please enter Mitchell username."} />
                        </GridItem>
                        <GridItem col={6}>
                            <Input
                                id="mitchellPassword"
                                className="mb-4"
                                name="mitchellPassword"
                                type="password"
                                label="Mitchell Password"
                                autoComplete="new-password"
                                role="presentation"
                                error={formError.mitchellPassword && "Please enter Mitchell password."} />
                        </GridItem>
                    </Grid>
                </form>
            </Box>
        </ContentLayout>
    </div>
  );
}
