const bmsProfileType = {
    'AIG': 'Apply Glass Adjustment',
    'AIGD': 'Apply Glass Discount',
    'AIGM': 'Apply Glass Markup',
    'AIP': 'Apply Profile Adjustment',
    'AIPD': 'Apply Profile Discount',
    'AIPM': 'Apply Profile Markup',
    'BEAC': 'Betterment - Additional Cost',
    'BELA': 'Betterment - Labor',
    'BELP': 'Betterment - Labor and Paint',
    'BEPA': 'Betterment - Parts Betterment',
    'BEPL': 'Betterment - Parts and Labor',
    'BEPM': 'Betterment - Paint and Materials',
    'BEPO': 'Betterment - Parts, Labor, and Paint',
    'BEPP': 'Betterment - Parts and Paint',
    'BESL': 'Betterment - Sublet',
    'BETP': 'Betterment - Total Paint',
    'LA': 'Labor',
    'LAB': 'Labor - Body',
    'LABA': 'Labor - Body, Additional Labor',
    'LABS': 'Labor - Body, Sublet',
    'LACG': 'Labor, Chipguard',
    'LAD': 'Labor - Diagnostic',
    'LADT': 'Labor - Drill Time',
    'LAE': 'Labor - Electrical',
    'LAE2': 'Labor - Two-Stage Edge',
    'LAET': 'Labor - Edging Time',
    'LAF': 'Labor - Frame',
    'LAFA': 'Labor - Frame, Additional Labor',
    'LAFS': 'Labor - Frame, Sublet',
    'LAG': 'Labor - Glass',
    'LAGA': 'Labor - Glass, Additional Labor',
    'LAGS': 'Labor - Glass, Sublet',
    'LAM': 'Labor - Mechanical',
    'LAMA': 'Labor - Mechanical, Additional Labor',
    'LAMS': 'Labor - Mechanical, Sublet',
    'LAR': 'Labor - Refinish',
    'LARA': 'Labor - Refinish, Additional Labor',
    'LARS': 'Labor - Refinish, Sublet',
    'LAS': 'Labor - Structural',
    'LASA': 'Labor - Structural, Additional Labor',
    'LASS': 'Labor - Structural, Sublet',
    'LASU': 'Labor - Surface',
    'LAT': 'Labor - Total',
    'LATT': 'Labor - Two Tone',
    'LAU': 'User Defined Labor',
    'LA1': 'User Defined Labor 1',
    'LA2': 'User Defined Labor 2',
    'LA2S': 'Labor - 2 Stage',
    'LA2U': 'Labor - 2 State Setup',
    'LA3': 'User Defined Labor 3',
    'LA3S': 'Labor - Three Stage',
    'LA4': 'User Defined Labor 4',
    'LAUT': 'Labor - Underside Time',
    'MABL': 'Materials - Blend',
    'MACS': 'Materials - Color Sand and Buff',
    'MA2S': 'Materials - Two-Stage Paint (Clear Coat)',
    'MA2T': 'Materials - Two Tone Paint',
    'MA3S': 'Materials - Three-Stage Paint',
    'MAHW': 'Materials - Hazardous Waste',
    'MAPA': 'Materials - Paint',
    'MASH': 'Materials - Shop',
    'MAT': 'Materials - Total',
    'MATD': 'Materials - Tire Disposal',
    'NTC': 'Non-Taxable Costs',
    'NTL': 'Non-Taxable Labor',
    'NTP': 'Non-Taxable Parts',
    'OP0': 'Blank',
    'OP1': 'Refinish/Repair',
    'OP2': 'Remove/Install',
    'OP3': 'Additional Repair',
    'OP4': 'Alignment',
    'OP5': 'Overhaul',
    'OP6': 'Refinish',
    'OP7': 'Inspect',
    'OP8': 'Check/Adjust',
    'OP9': 'Repair',
    'OP10': 'Repair, Partial',
    'OP11': 'Remove/Replace',
    'OP12': 'Remove/Replace, Partial',
    'OP13': 'Additional Costs',
    'OP14': 'Additional Operations',
    'OP15': 'Blend',
    'OP16': 'Sublet',
    'OP17': 'Related Prior Damage',
    'OP18': 'Appearance Allowance',
    'OP19': 'Unrelated Prior Damage',
    'OP20': 'Remove and Reinstall',
    'OP21': 'Remove and Repair',
    'OP22': 'Repair First, else Replace',
    'OP23': 'Redo',
    'OTA': 'Other - Allowances',
    'OTAA': 'Other - Appearance Allowance',
    'OTAC': 'Other - Additional Cost',
    'OTBE': 'Other - Betterment',
    'OTGL': 'Other - Related Non-Glass Damage',
    'OTCO': 'Other - Coolant',
    'OTDE': 'Other - Detail/Cleanup',
    'OTFO': 'Other - Freon & Oil',
    'OTEC': 'Other - Estimate Charge',
    'OTLU': 'Other Lubricants',
    'OTP': 'Other Paint',
    'OTRC': 'Other - Rust Coating',
    'OTSH': 'Other - Special Paint Material',
    'OTSI': 'Other - Shipping',
    'OTSL': 'Other - Sublet',
    'OTSP': 'Other - Special Paint Material',
    'OTST': 'Other - Storage',
    'OTTR': 'Other - Temporary Repairs',
    'OTTW': 'Other - Towing',
    'PA': 'Parts',
    'PAA': 'Parts - Aftermarket (QRP)',
    'PAC': 'Parts - Rechromed',
    'PAE': 'Parts - Existing',
    'PAG': 'Parts - Glass',
    'PAGF': 'Glass – Front',
    'PAGR': 'Glass – Rear',
    'PAGD': 'Glass – Driver’s Side',
    'PAGP': 'Glass – Passenger’s Side',
    'PAGQ': 'Glass – Quarter Glass',
    'PAL': 'Parts - Like Kind and Quality (LKQ)',
    'PAM': 'Parts - Remanufactured',
    'PAN': 'Parts - New',
    'PAO': 'Parts - Other',
    'PAP': 'Parts - New, Partial',
    'PAR': 'Parts - Re-cored',
    'PASL': 'Parts - Sublet',
    'PAT': 'Parts - Total',
    'RPD': 'Related Prior Damage',
    'TOT': 'Total',
    'UPD': 'Unrelated Prior Damage',
  }

export default bmsProfileType;