import { useState, useContext, useEffect } from "react";
import { GlobalContextDispatch } from "../../context/globalContext";
import useFetchMutation from "../../hooks/useFetchMutation";
import { FormImage } from "../../styled/styles/form";
import ButtonLink from "../../styled/base/ButtonLink/ButtonLink";
import { baseUrl } from '../../config';
import { Flex, Typography, TextInput, Alert, Button, Box } from "@strapi/design-system";
const INITIAL_FORM_DATA = {
  email: ""
};

const INITIAL_FORM_ERRORS = {
  email: false
};

const forgotPassUrl = `${baseUrl}/api/auth/forgot-password`;

export default function ForgotPassword({ setSelection }) {
  const dispatch = useContext(GlobalContextDispatch);
  const [forgotPassword, { loading, error, data }] = useFetchMutation(forgotPassUrl);
  const [resetStatus, setResetStatus] = useState(undefined);

  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [formError, setFormError] = useState(INITIAL_FORM_ERRORS);

  useEffect(() => {
    if (data) {
      if (data.ok) {
        setResetStatus({ type: "success" });
      }else{
        setResetStatus({ type: "error" });
      }
    }
  }, [data, dispatch]);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  }

  function formValidation(formData) {
    let hasError = true;
    if (formData.email !== "") {
      // validate email format by regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(formData.email)) {
        hasError = false;
        setFormError((prevState) => ({ ...prevState, email: false }));
      }else {
        setFormError((prevState) => ({ ...prevState, email: true }));
      }
    }else {
      setFormError((prevState) => ({ ...prevState, email: true }));
    }
    return hasError;
  }

  async function hadleFormSubmit(event) {
    event.preventDefault();
    const hasErrors = formValidation(formData);

    if (!hasErrors) {
      const forgotPayload = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      };
      forgotPassword(forgotPayload);
    }
  }

  return (
    <>
    <Flex className="h-full" alignItems="center" justifyContent="center">
      <Box className="w-full max-w-lg m-auto" color="neutral800" padding={10} background="neutral0">
        <FormImage src="https://api.trueclaim.ai/uploads/tc_icon_pink_c0e138ecfc.png" alt="Workflow"/>
        <Typography as="h2" textAlign="center" className="mt-2" variant="alpha">Welcome to Trueclaim</Typography>
        <Typography as="p" variant="epsilon" textAlign="center"  textColor="neutral600" className="mt-2">Reset your account password</Typography>
        <Box marginTop={6}>
          {error && (
            <Box marginBottom={6}>
            <Alert className="mt-6" closeLabel="Close" variant="danger">
              {error.message}
            </Alert>
            </Box>
          )}
          {resetStatus?.type === 'success' && (
            <Box marginBottom={6}>
            <Alert className="mt-6" closeLabel="Close" variant="success">
              If the email exists in our system, you will receive an email with instructions to reset your password
            </Alert>
            </Box>
          )}
          {resetStatus?.type === 'error' && (
            <Box marginBottom={6}>
            <Alert className="mt-6" closeLabel="Close" variant="danger">
              Something went wrong. Please try again or contact support
            </Alert>
            </Box>
          )}
          <Flex direction="column" alignItems="stretch" gap={6}>
            <TextInput type="text" 
                label="Email" 
                name="email" 
                placeholder="Enter your email address"
                required
                onChange={handleInputChange}
                value={formData.email}
                error={formError.email && "Please provide a valid email."} />

            <Button fullWidth onClick={hadleFormSubmit} disabled={loading}>Reset Password</Button>
            <Flex justifyContent="flex-end">
              <ButtonLink onClick={() => setSelection("signin")}>Go back</ButtonLink>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Flex>
    </>
  );
}
