import { useEffect, useContext, useState } from "react";
import { GlobalContextDispatch } from "../context/globalContext";
import { useHistory, NavLink } from "react-router-dom";
import {
  Box,
  Flex,
  Typography
} from "@strapi/design-system";
import { Link } from "@strapi/design-system/v2";
import { FormImage } from "../styled/styles/form";

export default function Logout() {
  const dispatch = useContext(GlobalContextDispatch);
  let history = useHistory();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    dispatch({ type: "LOGOUT" });

    const script = document.createElement("script");
    const scriptText = document.createTextNode(`
      chrome.runtime.sendMessage(
      'egepamljlmjcnnhbenimmbopbfpbecee',
      { type: 'logged-out', data: '' },
      { includeTlsChannelId: true },
      function(response) {
        console.log('response', response);
      }
    );`);
    script.appendChild(scriptText);
    document.body.appendChild(script);

    const timer = setTimeout(() => {
      return history.push("/");
    }, 5000);

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Clear the interval and timeout when the component is unmounted
    return () => {
      clearTimeout(timer);
      clearInterval(countdownInterval);
    };
  }, []);

  return (
    <Box
      className="h-full"
      paddingTop={2}
      paddingBottom={11}
      background="neutral100"
    >
      <Flex className="h-full" alignItems="center" justifyContent="center">
        <Box
          className="w-full max-w-lg m-auto"
          color="neutral800"
          padding={10}
          background="neutral0"
        >
          <FormImage
            src="https://api.trueclaim.ai/uploads/tc_icon_pink_c0e138ecfc.png"
            alt="Workflow"
          />
          <Typography
            as="h2"
            textAlign="center"
            className="mt-2"
            variant="alpha"
          >
            Logout successful
          </Typography>
          <Typography
            as="p"
            variant="epsilon"
            textAlign="center"
            textColor="neutral600"
            className="mt-2"
          >
            Redirecting to the login page in {countdown} seconds
          </Typography>
          <Flex justifyContent="center" paddingTop={4}>
            <Link as={NavLink} to="/">
              Go to the login page now
            </Link>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
