import React from "react";

const AddOperations = (props) => {
  const { 
    adasOperation,
    index,
    adasReportDataLength,
    deleteAddtionalOperation,
    editAddtionalOperation,
    vehicleName
  } = props;

  return (
    <div
      key={index}
      id={`section${adasReportDataLength + index + 1}`}
      className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700"
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row">
          <h6 className="mb-2 max-w-full text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            {adasReportDataLength + index + 1}. {adasOperation.Sensor}
          </h6>
          <div className="flex space-x-4">
            <svg fill="currentColor" viewBox="0 0 20 20" className="ml-2 h-7 w-7 text-green-700 dark:text-green-600" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path clipRule="evenodd" fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"></path></svg>
          </div>
        </div>
        <div className="flex">
          <button
            type="button"
            onClick={() => deleteAddtionalOperation(adasOperation.CustomOperationId)}
            className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
          >
            <svg
              fill="none"
              className="h-4 w-4"
              stroke="currentColor"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
            <span className="sr-only">Incorrect</span>
          </button>
          <button
            type="button"
            onClick={() => editAddtionalOperation(adasOperation.CustomOperationId)}
            className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
          >
            <svg
              fill="none"
              className="h-4 w-4"
              stroke="currentColor"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path d="M3 21v-3.75l10.593-10.593 3.75 3.75L6.75 21H3zm14.297-13.406l-3.75-3.75 2.1-2.1a1.2 1.2 0 011.694 0l2.056 2.056a1.2 1.2 0 010 1.694l-2.1 2.1z" fill="currentColor"/>
            </svg>
            <span className="sr-only">Edit</span>
          </button>
        </div>
      </div>
      {adasOperation.OperationType !== 'Safety Operations' ? (
        <ul className="max-w-full list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400">
          <li>
            <span className="font-bold">Procedure Type: </span>{" "}
            {adasOperation.Calibration === 'static' ? "Static Calibration" : ""}
            {adasOperation.Calibration === 'dynamic' ? "Dynamic Calibration" : ""}
          </li>
          <li>
            <span className="font-bold">Location: </span>
            {adasOperation.Location ? adasOperation.Location : "N/A"}
          </li>
          <li>
            <span className="font-bold">Tools Required: </span>
            {adasOperation.Tools ? adasOperation.Tools : "N/A"}
          </li>
          <li>
            <span className="font-bold">Responsible For: </span>
            {adasOperation.ResponsiveFor ? adasOperation.ResponsiveFor : "N/A"}
          </li>
        </ul>
      ) : (
        <ul className="max-w-full list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400">
          <li>
            <span className="font-bold">Procedure Type: </span>
            {adasOperation.ResponsiveFor ? adasOperation.ResponsiveFor : "N/A"}
          </li>
        </ul>
      )}
      {adasOperation.LineItems.LineItem && (
        <>
          <p className="mt-4 font-normal text-gray-500 dark:text-gray-400">Per the {vehicleName} repair manual, the Test necessitates the above mentioned procedure should any of the following occur:</p>
          <ul className="max-w-full list-inside list-decimal space-y-1 space-y-1 text-gray-500 dark:text-gray-400">
            {adasOperation.LineItems.LineItem.length ? (
              <>
                {adasOperation.LineItems.LineItem.map((lineItem, index) => (
                  <li key={index}>{lineItem.Part} is {lineItem.Operation} (Line {lineItem.LineNumber})</li>
                 ))}
              </>
            ) : (
              <li>{adasOperation.LineItems.LineItem.Part} is {adasOperation.LineItems.LineItem.Operation} (Line {adasOperation.LineItems.LineItem.LineNumber})</li>
            )}
          </ul>
        </>
      )}
      {adasOperation.DocumentLink && (
        <p className="mt-4 font-normal text-gray-500 dark:text-gray-400">
          Please consult the below manufacturer-provided documentation for complete instructions and requirements <a href={adasOperation.DocumentLink} target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">here</a>
        </p>
      )}
      <div className="flex items-center justify-between">
        <div className="font-small text-blue-600 hover:cursor-pointer hover:underline dark:text-blue-500">
          &nbsp;
        </div>
        <div className="flex items-center justify-end space-x-5 pt-5">
          <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
            <div className="flex rounded-lg border-transparent">
              <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                $
              </span>
              <input
                id="price-price-ADAS Operations-Headlight Control Module-0"
                className="bg-gray-50 w-28 p-2.5 pl-2 text-base block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                type="number"
              />
            </div>
            <span className="text-xs">Price</span>
          </div>
          <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
            <div className="flex rounded-lg border-transparent">
              <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                %
              </span>
              <input
                id="price-discount-ADAS Operations-Headlight Control Module-0"
                className="bg-gray-50 w-20 p-2 pl-1 text-base block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                type="number"
              />
            </div>
            <span className="text-xs">Discount</span>
          </div>
          <div className="mr-2 flex flex-col items-end justify-end pl-5 text-lg font-semibold text-gray-500">
            <div className="my-2">${adasOperation.Price}</div><span className="text-xs ">Final Price</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddOperations;