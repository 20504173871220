import { useState, useContext, useEffect } from "react";
import { GlobalContextDispatch } from "../../context/globalContext";
import useFetchMutation from "../../hooks/useFetchMutation";
import { FormImage } from "../../styled/styles/form";
import ButtonLink from "../../styled/base/ButtonLink/ButtonLink";
import { baseUrl } from '../../config';
import { Flex, Typography, TextInput, Alert, Button, Box, Link } from "@strapi/design-system";

const INITIAL_FORM_DATA = {
  identifier: "",
  username: "",
  password: "",
};

const INITIAL_FORM_ERRORS = {
  identifier: false,
  username: false,
  password: false,
};

const loginUrl = `${baseUrl}/api/auth/local`;

export default function Login({ setSelection }) {
  const dispatch = useContext(GlobalContextDispatch);
  const [login, { loading, error, data }] = useFetchMutation(loginUrl);
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [formError, setFormError] = useState(INITIAL_FORM_ERRORS);

  useEffect(() => {
    if (data) {
      const { jwt, user } = data;
      dispatch({ type: "LOGIN", payload: { jwt, user } });
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (formData.username !== formData.identifier) {
      setFormData({ ...formData, identifier: formData.username });
    }
  }, [formData]);

  function handleInputChange(event) {
    const { name, value } = event.target;
    if(name === "username") {
      setFormData({ ...formData, identifier: value });
    }
    setFormData({ ...formData, [name]: value });
  }

  function formValidation(formData) {
    let hasError = false;
    if (formData.identifier === "") {
      hasError = true;
      setFormError((prevState) => ({ ...prevState, identifier: true }));
    }
    if (formData.password === "") {
      hasError = true;
      setFormError((prevState) => ({ ...prevState, password: true }));
    }
    return hasError;
  }

  async function hadleFormSubmit(event) {
    event.preventDefault();
    const hasErrors = formValidation(formData);

    if (!hasErrors) {
      const loginPayload = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      };
      login(loginPayload);
    }else {
      console.log(formData);
    }
  }

  return (
    <>
    <Flex className="h-full" alignItems="center" justifyContent="center">
      <Box className="w-full max-w-lg m-auto" color="neutral800" padding={10} background="neutral0">
        <FormImage src="https://api.trueclaim.ai/uploads/tc_icon_pink_c0e138ecfc.png" alt="Workflow"/>
        <Typography as="h2" textAlign="center" className="mt-2" variant="alpha">Welcome to Trueclaim</Typography>
        <Typography as="p" variant="epsilon" textAlign="center"  textColor="neutral600" className="mt-2">Sign in with your account</Typography>
        <Box marginTop={6}>
          {error && (
            <Box marginBottom={6}>
            <Alert className="mt-6" closeLabel="Close" variant="danger">
              {error.message}
            </Alert>
            </Box>
          )}
          <form onSubmit={hadleFormSubmit}>
          <Flex direction="column" alignItems="stretch" gap={6}>
            <TextInput type="text" 
                label="Username" 
                name="username" 
                placeholder="Enter your username"
                required
                onChange={handleInputChange}
                value={formData.username}
                error={formError.username && "Please enter username."} />

            <TextInput type="password"
                label="Password" 
                name="password" 
                placeholder="Enter your password"
                required
                onChange={handleInputChange}
                value={formData.password}
                error={formError.password && "Please enter password"} />

            <Button fullWidth onClick={hadleFormSubmit} disabled={loading}>Login</Button>
            <Button type="submit" className="hidden-input">Login</Button>
            <Flex justifyContent="space-between">
              <Link to="/body-shop-registration" className="btn btn-primary">Sign up</Link>
              <ButtonLink onClick={() => setSelection("forgot")}>Forgot password?</ButtonLink>
            </Flex>
          </Flex>
          </form>
        </Box>
      </Box>
    </Flex>
    </>
  );
}
