import React, { useEffect, useState, useContext } from "react";
import parse from "html-react-parser";
import { Button, RadioGroup, Checkbox } from '@strapi/design-system';
import { Download, Envelop, Clock, Cog } from '@strapi/icons';
import { GlobalContextState } from "../../context/globalContext";
import { baseUrl } from "../../config";
import { useParams } from "react-router-dom";
import AddOperations from "./AddOperations";
const Adas = ({ 
    adasReportData, 
    positionStatement, 
    isSuperAdmin,
    sendEmailReport,
    isSendingEmail,
    setIsSendingEmail,
    customOperation,
    setCustomOperation,
    claimData
  }) => {
  const token = useContext(GlobalContextState).token;
  const { urlkey } = useParams();
  const downloadPdfUrl = `${baseUrl}/api/tc-app/download-adas-report/${urlkey}`;
  const [showSafety, setShowSafety] = useState(true);
  const [addOperation, setAddOperation] = useState(false);
  const [shareReport, setShareReport] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSubmittingOperation, setIsSubmittingOperation] = useState(false);

  const [isStaticChecked, setIsStaticChecked] = useState(false);
  const [isDynamicChecked, setIsDynamicChecked] = useState(false);

  const [formOperationType, setFormOperationType] = useState("ADAS Operations");

  const [ctOAdasOperation, setCtOAdasOperation] = useState([]);
  const [ctOSafetyOperation, setCtOSafetyOperation] = useState([]);
  const [ctOSteeringOperation, setCtOSteeringOperation] = useState([]);
  const [ctOFunctionalOperation, setCtOFunctionalOperation] = useState([]);

  // Number of opperations for each section
  const [adasOperationsCount, setAdasOperationsCount] = useState(0);
  const [safetyOperationsCount, setSafetyOperationsCount] = useState(0);
  const [steeringOperationsCount, setSteeringOperationsCount] = useState(0);
  const [functionalOperationsCount, setFunctionalOperationsCount] = useState(0);

  // Number of total operations for each section
  const [totalAdasOperations, setTotalAdasOperations] = useState(0);
  const [totalSafetyOperations, setTotalSafetyOperations] = useState(0);
  const [totalSteeringOperations, setTotalSteeringOperations] = useState(0);
  const [totalFunctionalOperations, setTotalFunctionalOperations] = useState(0);

  const [flagDeleteAddtionalOperation, setFlagDeleteAddtionalOperation] = useState(false);
  const [flagDeleting, setFlagDeleting] = useState(false);
  const [actionOperationId, setActionOperationId] = useState("");

  const [selected, setSelected] = useState();
  
  const handleStaticChange = () => {
    setIsStaticChecked(!isStaticChecked);
    setIsDynamicChecked(false);
  };

  const handleDynamicChange = () => {
    setIsDynamicChecked(!isDynamicChecked);
    setIsStaticChecked(false);
  };

  useEffect(() => {
    let ctOAdasOp = [];
    let ctOSafetyOp = [];
    let ctOFunctionalOp = [];
    let ctOSteeringOp = [];
    if(customOperation.CustomOperations) {
      let customOperations = customOperation.CustomOperations.CustomOperation;
      if (!Array.isArray(customOperations)) {
        customOperations = [customOperations];
      }
      customOperations.forEach((operation) => {
        if(operation.OperationType === "ADAS Operations") {
          ctOAdasOp.push(operation);
        } else if(operation.OperationType === "Safety Operations") {
          ctOSafetyOp.push(operation);
        } else if(operation.OperationType === "Functional Operations") {
          ctOFunctionalOp.push(operation);
        } else if(operation.OperationType === "Steering Operations") {
          ctOSteeringOp.push(operation);
        }
      });
    }

    setCtOAdasOperation(ctOAdasOp);
    setCtOSafetyOperation(ctOSafetyOp);
    setCtOSteeringOperation(ctOSteeringOp);
    setCtOFunctionalOperation(ctOFunctionalOp);

    if (adasReportData && adasReportData.adasOperations) {
      setAdasOperationsCount(adasReportData.adasOperations.length);
      setTotalAdasOperations(adasReportData.adasOperations.length + ctOAdasOp.length);
    } else {
      setTotalAdasOperations(ctOAdasOp.length);
    }

    if (adasReportData && adasReportData.safetyOperations) {
      setSafetyOperationsCount(adasReportData.safetyOperations.length);
      setTotalSafetyOperations(adasReportData.safetyOperations.length + ctOSafetyOp.length);
    } else {
      setTotalSafetyOperations(ctOSafetyOp.length);
    }

    if (adasReportData && adasReportData.steeringOperations) {
      setSteeringOperationsCount(adasReportData.steeringOperations.length);
      setTotalSteeringOperations(adasReportData.steeringOperations.length + ctOSteeringOp.length);
    } else {
      setTotalSteeringOperations(ctOSteeringOp.length);
    }

    if (adasReportData && adasReportData.functionalOperations) {
      setFunctionalOperationsCount(adasReportData.functionalOperations.length);
      setTotalFunctionalOperations(adasReportData.functionalOperations.length + ctOFunctionalOp.length);
    } else {
      setTotalFunctionalOperations(ctOFunctionalOp.length);
    }
  }, [customOperation, adasReportData]);

  const manualLines = [
    {
      description: "Recuperations des plastiques",
      operation: "Remove / Replace",
      type: "Body*",
      totalUnit: '0.0',
      qty: 1,
      totalPrice: '1.79',
    },
    {
      description: "Adhesion Promoters - Bumpers",
      operation: "Remove / Replace",
      type: "Refinish*",
      totalUnit: '0.0',
      qty: 1,
      totalPrice: '13.00',
    },
    {
      description: "Pre/Post Scan",
      operation: "Repair",
      type: "Body*",
      totalUnit: '0.0',
      qty: 1,
      totalPrice: '50.00',
    },
    {
      description: "Flex Additives - Bumpers",
      operation: "Remove / Replace",
      type: "Refinish*",
      totalUnit: '0.0',
      qty: 1,
      totalPrice: '15.00',
    },
    {
      description: "Wheel Alignment",
      operation: "Remove / Replace",
      type: "Body*",
      totalUnit: '0.0',
      qty: 1,
      totalPrice: '107.00',
    },
  ]

  const removeSection = (section) => {
    // Find the row-incorrect and add hidden class
    const sectionElement = document.getElementById(`${section}-incorrect`);
    sectionElement.classList.add("hidden");
    // Find the child with class row-correct and remove hidden class
    const sectionCorrectElement = document.getElementById(`${section}-correct`);
    sectionCorrectElement.classList.remove("hidden");
  };

  const activeSection = (section) => {
    // Find the row-incorrect and add hidden class
    const sectionElement = document.getElementById(`${section}-incorrect`);
    sectionElement.classList.remove("hidden");
    // Find the child with class row-correct and remove hidden class
    const sectionCorrectElement = document.getElementById(`${section}-correct`);
    sectionCorrectElement.classList.add("hidden");
  };

  const showHideSafetyOperations = () => {
    setShowSafety(!showSafety);
    const safetyOperations = document.querySelectorAll(".js-safety-operations");
    safetyOperations.forEach((section) => {
      section.classList.toggle("hidden");
    });
  };

  const getTriggerLinesHtml = (triggerLines) => {
    let triggerLinesHtml = "";
    let triggerLinesMap = new Map();
    triggerLines.forEach((line) => {
      if (triggerLinesMap.has(line.description)) {
        triggerLinesMap.get(line.description).push(line);
      } else {
        triggerLinesMap.set(line.description, [line]);
      }
    });
    triggerLinesMap.forEach((lines, key) => {
      let lineNumbers = lines.map((line) => line.no).join(",");
      if (!lineNumbers) {
        lineNumbers = lines.map((line) => line.lineNumber).join(",");
      }
      if(lineNumbers) {
        triggerLinesHtml += `<li>${key} is involved (Line ${lineNumbers})</li>`;
      } else {
        triggerLinesHtml += `<li>${key} is involved</li>`;
      }
    });
    return parse(triggerLinesHtml);
  };

  const getTriggersHtml = (triggers) => {
    let triggerLinesHtml = "";
    triggers.forEach((trigger) => {
      triggerLinesHtml += `<li>${trigger}</li>`;
    });
    return parse(triggerLinesHtml);
  };

  const downloadPdf = async () => {
    setIsDownloading(true);
    console.log("Download PDF");
    const pdfUrl = await generatePdfUrl();
    window.open(pdfUrl, "_blank");
    setIsDownloading(false);
  }

  const shareEmailReport = async () => {
    setIsSendingEmail(true);
    const shareReportError = document.getElementById("share-report-error");
    const emails = document.getElementById("emails_share").value;
    shareReportError.classList.add("hidden");
    let canSubmit = false
    if(emails) {
      console.log("Emails", emails);
      const emailList = emails.split(",");
      canSubmit = emailList.every((email) => {
        return email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
      });
    }
    const pdfUrl = await generatePdfUrl();
    if(pdfUrl && canSubmit) {
      await sendEmailReport(emails, pdfUrl);
      setShareReport(false);
    }else {
      shareReportError.classList.remove("hidden");
    }
    setIsSendingEmail(false);
  }

  const generatePdfUrl = async () => {
    try {
      const fetchOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(downloadPdfUrl, fetchOptions);
      const data = await response.json();
      if(data.status === "success") {
        return data.link;
      } else {
        alert("Error downloading PDF");
      }
    } catch (error) {
      console.error(error);
    }
  }

  const deleteAddtionalOperation = (index) => {
    setFlagDeleteAddtionalOperation(true);
    setActionOperationId(index);
  }

  const editAddtionalOperation = (index) => {
    setAddOperation(true);

    const operation = customOperation.CustomOperations.CustomOperation.find((operation) => operation.CustomOperationId === index);
    document.getElementById("operation_id").value = operation.CustomOperationId;
    document.getElementById("operation_ct_type").value = operation.OperationType;
    document.getElementById("sensor").value = operation.Sensor;
    document.getElementById("location").value = operation.Location;
    if(operation.Calibration === "static") {
      setIsStaticChecked(true);
      setIsDynamicChecked(false);
    }else {
      setIsStaticChecked(false);
      setIsDynamicChecked(true);
    }

    document.getElementById("tools").value = operation.Tools;
    document.getElementById("responsible").value = operation.ResponsiveFor;
    document.getElementById("documentation").value = operation.DocumentLink;
    document.getElementById("price-add-operation-price").value = operation.Price;

    const lineItems = document.getElementById("lineitems-add");
    lineItems.innerHTML = "";
    let lineItemsData = operation.LineItems.LineItem;
    if(lineItemsData){
      if (!Array.isArray(lineItemsData)) {
        lineItemsData = [lineItemsData];
      }
      lineItemsData.forEach((lineItem) => {
        const rowContent = `<div className="mb-2 mt-2 flex justify-between space-x-4 lineitems-item">
                                <input
                                  className="block w-full flex-1 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400"
                                  placeholder="Grille"
                                  type="text"
                                  name="part[]"
                                  value="${lineItem.Part}"
                                />
                                <input
                                  className="block w-full flex-1 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400"
                                  placeholder="Installed"
                                  type="text"
                                  name="operation[]"
                                  value="${lineItem.Operation}"
                                />
                                <input
                                  className="block w-full flex-1 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400"
                                  placeholder="1"
                                  type="number"
                                  name="linenumber[]"
                                  value="${lineItem.LineNumber}"
                                />
                                <button 
                                  type="button"
                                  className="lineitems-remove  ml-2 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                                  <svg fill="none" className="h-5 w-5" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg> 
                                  <span className="sr-only">Remove Row</span>
                                </button>
                              </div>`;
        const clone = document.createElement("div");
        clone.innerHTML = rowContent;
        lineItems.appendChild(clone);
        clone.querySelector(".lineitems-remove").addEventListener("click", removeLineItem);
      });
    }
  }

  const formSubmitDeleteAddtionalOperation = async () => {
    setFlagDeleting(true);
    const operationId = actionOperationId;
    setActionOperationId(0);

    console.log("Operation IDs", operationId);
    if(operationId){
      const formData = {
        urlkey: urlkey,
        operationId: operationId
      }
  
      const submitCustomOperation = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      }
  
      fetch(`${baseUrl}/api/tc-app/delete-custom-operation`, submitCustomOperation)
        .then((response) => response.json())
        .then((data) => {
          setCustomOperation(data);
          setFlagDeleteAddtionalOperation(false);
          setFlagDeleting(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }else {
      setFlagDeleting(false);
      setFlagDeleteAddtionalOperation(false);
    }
  }

  const addOperationForm = () => {
    setIsSubmittingOperation(true);
    // Loop through all items with class lineitems-item and get the values
    const lineItems = document.querySelectorAll(".lineitems-item");
    let lineItemsData = [];
    if(lineItems){
      lineItems.forEach((lineItem) => {
        const part = lineItem.querySelector("input[name='part[]']").value;
        const operation = lineItem.querySelector("input[name='operation[]']").value;
        const lineNumber = lineItem.querySelector("input[name='linenumber[]']").value;
        // If any one is not empty, add to lineItemsData
        if(part || operation || lineNumber) {
          lineItemsData.push({
            part: part,
            operation: operation,
            lineNumber: lineNumber,
          });
        }
      });
    }

    const formData = {
      urlkey: urlkey,
      customOperationId: document.getElementById("operation_id").value,
      operationType: document.getElementById("operation_ct_type").value || "ADAS Operations",
      sensor: document.getElementById("sensor").value,
      location: document.getElementById("location") ? document.getElementById("location").value : "",
      calibration: isStaticChecked ? "static" : "dynamic",
      tools: document.getElementById("tools") ? document.getElementById("tools").value : "",
      responsiveFor: document.getElementById("responsible").value,
      lineItems: lineItemsData,
      documentLink: document.getElementById("documentation").value,
      price: document.getElementById("price-add-operation-price").value,
    }

    const submitCustomOperation = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    }

    fetch(`${baseUrl}/api/tc-app/add-custom-operation`, submitCustomOperation)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setCustomOperation(data);
        setAddOperation(false);
        setIsSubmittingOperation(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const addNewOperation = () => {
    setAddOperation(true);
    document.getElementById("operation_id").value = "";
    document.getElementById("operation_ct_type").value = "ADAS Operations";
    document.getElementById("sensor").value = "";
    document.getElementById("location").value = "";
    document.getElementById("static").checked = true;
    document.getElementById("tools").value = "";
    document.getElementById("responsible").value = "";
    document.getElementById("documentation").value = "";
    document.getElementById("price-add-operation-price").value = "";
    const lineItems = document.getElementById("lineitems-add");
    lineItems.innerHTML = "";
  }

  if (!adasReportData || (adasReportData.status && adasReportData.status === "failed")) {
    return <div>Error getting ADAS report</div>;
  }

  function scrollToElement(id) {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
  }

  function addRow() {
    // Add one more lineitems-item for lineitems-add
    const lineItems = document.getElementById("lineitems-add");
    const rowContent = `<div className="mb-2 mt-2 flex justify-between space-x-4 lineitems-item">
                            <input
                              className="block w-full flex-1 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400"
                              placeholder="Grille"
                              type="text"
                              name="part[]"
                            />
                            <input
                              className="block w-full flex-1 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400"
                              placeholder="Installed"
                              type="text"
                              name="operation[]"
                            />
                            <input
                              className="block w-full flex-1 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400"
                              placeholder="1"
                              type="number"
                              name="linenumber[]"
                            />
                            <button 
                              type="button"
                              className="lineitems-remove  ml-2 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                              <svg fill="none" className="h-5 w-5" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg> 
                              <span className="sr-only">Remove Row</span>
                            </button>
                          </div>`;
    
    const clone = document.createElement("div");
    clone.innerHTML = rowContent;
    // Add removeLineItem event listener to the new button
    lineItems.appendChild(clone);
    clone.querySelector(".lineitems-remove").addEventListener("click", removeLineItem);
  }

  function removeLineItem(e) {
    const lineItem = e.target.closest(".lineitems-item");
    lineItem.remove();
  }

  function removeFirstLine() {
    const lineItem = document.querySelector(".lineitems-item.first-line");
    if (lineItem) {
      lineItem.remove();
    }
  }

  return (
    <div className="leading-6 dark">
      <div className="fixed right-4 top-1/2 transform -translate-y-1/2 flex flex-col items-center space-y-4">
        {totalAdasOperations > 0 && (
          <div className="relative group" onClick={() => scrollToElement("adas-operations")}>
            <div className="w-4 h-4 bg-gray-500 rounded-full hover:bg-gray-700 cursor-pointer"></div>
            <span className="whitespace-nowrap absolute right-8 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 group-hover:right-6 transition-all duration-300 bg-gray-900 text-white text-xs px-2 py-2 rounded-md">
              ADAS Operations
            </span>
          </div>
        )}
        {totalSafetyOperations > 0 && (
          <div className="relative group" onClick={() => scrollToElement("safety-operations")}>
            <div className="w-4 h-4 bg-gray-500 rounded-full hover:bg-gray-700 cursor-pointer"></div>
            <span className="whitespace-nowrap absolute right-8 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 group-hover:right-6 transition-all duration-300 bg-gray-900 text-white text-xs px-2 py-2 rounded-md">
              Safety Operations
            </span>
          </div>
        )}
        {totalSteeringOperations > 0 && (
          <div className="relative group" onClick={() => scrollToElement("steering-operations")}>
            <div className="w-4 h-4 bg-gray-500 rounded-full hover:bg-gray-700 cursor-pointer"></div>
            <span className="whitespace-nowrap absolute right-8 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 group-hover:right-6 transition-all duration-300 bg-gray-900 text-white text-xs px-2 py-2 rounded-md">
              Steering Operations
            </span>
          </div>
        )}
        {totalFunctionalOperations > 0 && (
          <div className="relative group" onClick={() => scrollToElement("functional-operations")}>
            <div className="w-4 h-4 bg-gray-500 rounded-full hover:bg-gray-700 cursor-pointer"></div>
            <span className="whitespace-nowrap absolute right-8 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 group-hover:right-6 transition-all duration-300 bg-gray-900 text-white text-xs px-2 py-2 rounded-md">
              Functional Operations
            </span>
          </div>
        )}
        {isSuperAdmin && (
          <div className="relative group" onClick={() => scrollToElement("manual-lines")}>
            <div className="w-4 h-4 bg-gray-500 rounded-full hover:bg-gray-700 cursor-pointer"></div>
            <span className="whitespace-nowrap absolute right-8 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 group-hover:right-6 transition-all duration-300 bg-gray-900 text-white text-xs px-2 py-2 rounded-md">
             Manual Lines
            </span>
          </div>
        )}
        {isSuperAdmin && (
          <div className="relative group" onClick={() => scrollToElement("body-and-frame")}>
            <div className="w-4 h-4 bg-gray-500 rounded-full hover:bg-gray-700 cursor-pointer"></div>
            <span className="whitespace-nowrap absolute right-8 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 group-hover:right-6 transition-all duration-300 bg-gray-900 text-white text-xs px-2 py-2 rounded-md">
            Repair Procedures
            </span>
          </div>
        )}
        {positionStatement && positionStatement.length > 0 && (
          <div className="relative group" onClick={() => scrollToElement("position-statement")}>
            <div className="w-4 h-4 bg-gray-500 rounded-full hover:bg-gray-700 cursor-pointer"></div>
            <span className="whitespace-nowrap absolute right-8 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 group-hover:right-6 transition-all duration-300 bg-gray-900 text-white text-xs px-2 py-2 rounded-md">
            Position Statement
            </span>
          </div>
        )}
      </div>
      <div className="lg:m-4 block items-center justify-center rounded-lg border-2 border-gray-200 bg-gray-100 bg-strapi-neutral-800  p-6">
        <div className="flex grow flex-col">
          <div className="flex items-center justify-between">
            <div className="flex hidden flex-wrap items-center justify-start text-sm md:flex">
              <span className="mb-2 mr-2">
                <button
                  className="bg-white outline outline-slate-300 hover:bg-gray-300 inline-flex items-center justify-center px-3 py-2 font-medium rounded-md break-words text-gray-900"
                  type="button"
                >
                  <svg
                    fill="white"
                    strokeWidth="1.5"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    className="h-5 w-5"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 1v16M1 9h16"
                    ></path>
                  </svg>
                  <span className="ml-1">Sent to Estimatics</span>
                </button>
              </span>
              <span className="mb-2 mr-2">
                <button
                  className="bg-white outline outline-slate-300 hover:bg-gray-300 inline-flex items-center justify-center px-3 py-2 font-medium rounded-md break-words text-gray-900"
                  type="button"
                  onClick={() => addNewOperation()}
                >
                  <svg
                    fill="white"
                    strokeWidth="1.5"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    className="h-5 w-5"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 1v16M1 9h16"
                    ></path>
                  </svg>
                  <span className="ml-1">Add Operation</span>
                </button>
              </span>
              <span className="mb-2 mr-2">
                <button
                  className="bg-white outline outline-slate-300 hover:bg-gray-300 inline-flex items-center justify-center px-3 py-2 font-medium rounded-md break-words text-gray-900"
                  type="button"
                  onClick={() => setShareReport(true)}
                >
                  <svg
                    className="h-5 w-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 16"
                  >
                    <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"></path>
                    <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"></path>
                  </svg>
                  <span className="ml-1">Share Report</span>
                </button>
              </span>
              <span className="mb-2 mr-2">
                <Button
                  onClick={downloadPdf}
                  loading={isDownloading}
                  startIcon={<Download />}
                >
                  Download Report
                </Button>
              </span>
            </div>
            <div className="rounded-lg border-2 bg-gray-700 p-5 md:max-w-xs">
              <div className="inline-flex items-center rounded bg-red-200 px-3 py-1 text-sm font-semibold text-red-900">
                <svg
                  className="mr-1 h-3 w-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 17 20"
                >
                  <path d="M7.958 19.393a7.7 7.7 0 0 1-6.715-3.439c-2.868-4.832 0-9.376.944-10.654l.091-.122a3.286 3.286 0 0 0 .765-3.288A1 1 0 0 1 4.6.8c.133.1.313.212.525.347A10.451 10.451 0 0 1 10.6 9.3c.5-1.06.772-2.213.8-3.385a1 1 0 0 1 1.592-.758c1.636 1.205 4.638 6.081 2.019 10.441a8.177 8.177 0 0 1-7.053 3.795Z"></path>
                </svg>
                Attention
              </div>
              <div className="flex items-center pt-2">
                <label className="relative inline-flex cursor-pointer">
                  <input
                    className="peer sr-only"
                    type="checkbox"
                    checked={showSafety}
                    onChange={() => showHideSafetyOperations()}
                  />
                  <div className="peer h-4 w-9 rounded-full bg-gray-300 after:absolute after:bottom-[2px] after:left-[2px] after:right-[2px] after:top-[2px] after:h-3 after:w-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                </label>
                <label
                  htmlFor="checked-checkbox"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Vehicle has sustained collision damage
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-row lg:px-2 md:flex">
        {totalAdasOperations > 0 && (
        <div className="cursor-pointer lg:mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-neutral-500 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-neutral-500 md:mb-0" onClick={() => scrollToElement("adas-operations")}>
          <h1 className="mb-2 max-w-full text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
            {totalAdasOperations}
          </h1>
          <h1 className="text-l mb-2 max-w-full tracking-tight text-gray-500 dark:text-white">
            ADAS Operations
          </h1>
        </div>
        )}
        {totalSafetyOperations > 0 && (
        <div className="cursor-pointer js-safety-operations lg:mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700 md:mb-0" onClick={() => scrollToElement("safety-operations")}>
          <h1 className="mb-2 max-w-full text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
            {totalSafetyOperations}
          </h1>
          <h1 className="text-l mb-2 max-w-full tracking-tight text-gray-500 dark:text-white">
            Safety Operations
          </h1>
        </div>
        )}
        {totalSteeringOperations > 0 && (
        <div className="cursor-pointer lg:mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700 md:mb-0" onClick={() => scrollToElement("steering-operations")}>
          <h1 className="mb-2 max-w-full text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
            {totalSteeringOperations}
          </h1>
          <h1 className="text-l mb-2 max-w-full tracking-tight text-gray-500 dark:text-white">
            Steering Operations
          </h1>
        </div>
        )}
        {totalFunctionalOperations > 0 && (
        <div className="cursor-pointer lg:mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700 md:mb-0" onClick={() => scrollToElement("functional-operations")}>
          <h1 className="mb-2 max-w-full text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
            {totalFunctionalOperations}
          </h1>
          <h1 className="text-l mb-2 max-w-full tracking-tight text-gray-500 dark:text-white">
            Functional Operations
          </h1>
        </div>
        )}
        {isSuperAdmin && (
        <div className="cursor-pointer lg:mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700 md:mb-0" onClick={() => scrollToElement("manual-lines")}>
          <h1 className="mb-2 max-w-full text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
            5
          </h1>
          <h1 className="text-l mb-2 max-w-full tracking-tight text-gray-500 dark:text-white">
            Manual Lines
          </h1>
        </div>
        )}
        {isSuperAdmin && (
        <div className="cursor-pointer lg:mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700 md:mb-0" onClick={() => scrollToElement("body-and-frame")}>
          <h1 className="mb-2 max-w-full text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
            4
          </h1>
          <h1 className="text-l mb-2 max-w-full tracking-tight text-gray-500 dark:text-white">
            Repair Procedures
          </h1>
        </div>
        )}
        {positionStatement && positionStatement.length > 0 && (
          <div className="cursor-pointer lg:mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700 md:mb-0" onClick={() => scrollToElement("position-statement")}>
            <h1 className="mb-2 max-w-full text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
              {positionStatement.length}
            </h1>
            <h1 className="text-l mb-2 max-w-full tracking-tight text-gray-500 dark:text-white">
              Position Statement
            </h1>
          </div>
        )}
      </div>

      {adasReportData && adasReportData.adasSystems && (
      <div className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700">
        <h4 className="mb-2 max-w-full text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          ADAS Systems
        </h4>
        <div className="flex-row md:flex">
          <ul className="max-w-full flex-1 list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400 md:mr-2">
            {adasReportData.allAdasSystems
              .slice(0, adasReportData.allAdasSystems.length / 2)
              .map((adasSystem, index) => (
                <li key={index}>{adasSystem}</li>
              ))}
          </ul>
          <ul className="max-w-full flex-1 list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400 md:ml-2">
            {adasReportData.allAdasSystems
              .slice(adasReportData.allAdasSystems.length / 2)
              .map((adasSystem, index) => (
                <li key={index}>{adasSystem}</li>
              ))}
          </ul>
        </div>
      </div>
      )}

      {totalAdasOperations > 0 && (
        <>
        <div id="adas-operations" className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700">
          <h4 className="mb-2 max-w-full text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            ADAS Operations
          </h4>
        </div>
        {adasReportData && adasReportData.adasOperations && adasReportData.adasOperations.length > 0 && (
          <>
          {adasReportData.adasOperations.map((adasOperation, index) => (
            <div
              key={index}
              id={`section-adas-operation-${index}`}
              className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700"
            >
              <div id={`section-adas-operation-${index}-incorrect`} className="flex flex-row items-center justify-between">
                <div className="flex flex-row">
                  <h6 className="mb-2 max-w-full text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {index + 1}. {adasOperation.name}
                  </h6>
                </div>
                <div className="flex">
                  <button
                    onClick={() => removeSection(`section-adas-operation-${index}`)}
                    className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
                  >
                    <svg
                      fill="none"
                      className="h-4 w-4"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                    <span className="sr-only">Incorrect</span>
                  </button>
                </div>
              </div>
              <div id={`section-adas-operation-${index}-correct`} className="flex flex-row items-center justify-between hidden">
                <div className="flex flex-row">
                  <div className="flex space-x-4">
                    <h6 className="mb-2 max-w-full text-xl font-bold tracking-tight text-gray-900 line-through dark:text-white">{index + 1}. {adasOperation.name}</h6>
                    <svg fill="currentColor" className="ml-2 h-7 w-7 text-red-700 dark:text-red-600" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                      <path clipRule="evenodd" fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"></path>
                    </svg>
                  </div>
                </div>
                <div className="flex">
                  <button 
                    onClick={() => activeSection(`section-adas-operation-${index}`)}
                    className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                    <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path></svg>
                    <span className="sr-only">Correct</span>
                  </button>
                </div>
              </div>
              <ul className="max-w-full list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400">
                <li>
                  <span className="font-bold">Procedure Type: </span>{" "}
                  {adasOperation.procedureType ? adasOperation.procedureType : "N/A"}
                </li>
                {adasOperation.location && (
                  <li>
                    <span className="font-bold">Location: </span>
                    {adasOperation.location}
                  </li>
                )}
                {adasOperation.tools && (
                  <li>
                    <span className="font-bold">Tools Required: </span>
                    {adasOperation.tools}
                  </li>
                )}
                {adasOperation.trigger && (
                  <li>
                    <span className="font-bold">Repair/Installation Triggers: </span>
                    {adasOperation.trigger}
                  </li>
                )}
                {adasOperation.systems && (
                  <li>
                    <span className="font-bold">Responsible For: </span>
                    {adasOperation.systems}
                  </li>
                )}
              </ul>
              <p className="mt-4 font-normal text-gray-500 dark:text-gray-400">
                Per the {adasReportData.vehicleName} repair manual, the{" "}
                {adasOperation.name} necessitates the above mentioned procedure
                should any of the following occur:
              </p>
              <ul className="max-w-full list-inside list-decimal space-y-1 text-gray-500 dark:text-gray-400">
                <li>Vehicle is involved in a collision</li>
                {/* If trigger is not empty, split the trigger by comma and print every trigger */}
                {(adasOperation.triggerLines && adasOperation.triggerLines.length > 0 && (
                  getTriggerLinesHtml(adasOperation.triggerLines)
                ))}
                {(adasOperation.triggers && adasOperation.triggers.length > 0 && (
                  getTriggersHtml(adasOperation.triggers)
                ))}
              </ul>
              {adasOperation.externalLinks && adasOperation.externalLinks.length > 0 && (
                <div>
                  <p className="mt-4 font-normal text-gray-500 dark:text-gray-400">
                    OEM Procedure Requirements and Instructions:
                  </p>
                  {adasOperation.externalLinks.map((link, index) => (
                    <div className="flex flex-row items-center ml-5 my-5" key={index}>
                      <img src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${link.url}`} alt="Manufacturer Procedure Instructions" width={100} height={100} />
                      <a href={link.url} target="_blank" rel="noreferrer" className="ml-5">
                        <span className="font-medium text-blue-600 hover:underline dark:text-blue-500">
                          {link.name}
                        </span>
                      </a>
                    </div>
                  ))}
                </div>
              )}
              {!adasOperation.externalLinks || adasOperation.externalLinks.length === 0 && (
                <div>
                  <p className="mt-4 font-normal text-gray-500 dark:text-gray-400">
                    Please consult the below manufacturer-provided documentation for
                    complete instructions and requirements:
                  </p>
                  <div className="flex flex-row items-center ml-5 my-5">
                    <img src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${adasOperation.link}`} alt="Manufacturer Procedure Instructions" width={100} height={100} />
                    <a href={adasOperation.link} target="_blank" rel="noreferrer" className="ml-5">
                      <span className="font-medium text-blue-600 hover:underline dark:text-blue-500">
                        Manufacturer Procedure Instructions
                      </span>
                    </a>
                  </div>
                  <div className="flex flex-row items-center ml-5 my-5">
                    <img src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${adasOperation.repairLink}`} alt="Manufacturer Mandated Requirement" width={100} height={100} />
                    <a href={adasOperation.repairLink} target="_blank" rel="noreferrer" className="ml-5">
                      <span className="font-medium text-blue-600 hover:underline dark:text-blue-500">
                        Manufacturer Mandated Requirement
                      </span>
                    </a>
                  </div>
                </div>
              )}
              <div className="flex items-center justify-between">
                <div className="font-small text-blue-600 hover:cursor-pointer hover:underline dark:text-blue-500">
                  &nbsp;
                </div>
                <div className="flex items-center justify-end space-x-5 pt-5">
                  <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                    <div className="flex rounded-lg border-transparent">
                      <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                        $
                      </span>
                      <input
                        id="price-price-ADAS Operations-Headlight Control Module-0"
                        className="bg-gray-50 w-28 p-2.5 pl-2 text-base block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                        type="number"
                      />
                    </div>
                    <span className="text-xs">Price</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
          </>
        )}
        {ctOAdasOperation && ctOAdasOperation.map((adasOperation, index) => (
          <AddOperations
            key={index}
            adasOperation={adasOperation}
            index={index}
            adasReportDataLength={adasOperationsCount}
            deleteAddtionalOperation={deleteAddtionalOperation}
            editAddtionalOperation={editAddtionalOperation}
            vehicleName={adasReportData.vehicleName}
          />
        ))}
        </>
      )}

      {totalSafetyOperations > 0 && (
        <>
          <div id="safety-operations" className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700">
            <h4 className="mb-2 max-w-full text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Safety Operations
            </h4>
          </div>
          {adasReportData && adasReportData.safetyOperations && adasReportData.safetyOperations.length > 0 && (
            <>
            {adasReportData.safetyOperations.map((operation, index) => (
              <div
                key={index}
                id={`section-safety-operation-${index}`}
                className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700"
              >
                <div id={`section-safety-operation-${index}-incorrect`} className="flex flex-row items-center justify-between">
                  <div className="flex flex-row">
                    <h6 className="mb-2 max-w-full text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                      {index + 1}. {operation.name}
                    </h6>
                  </div>
                  <div className="flex">
                    <button
                      onClick={() => removeSection(`section-safety-operation-${index}`)}
                      className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
                    >
                      <svg
                        fill="none"
                        className="h-4 w-4"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                      <span className="sr-only">Incorrect</span>
                    </button>
                  </div>
                </div>
                <div id={`section-safety-operation-${index}-correct`} className="flex flex-row items-center justify-between hidden">
                  <div className="flex flex-row">
                    <div className="flex space-x-4">
                      <h6 className="mb-2 max-w-full text-xl font-bold tracking-tight text-gray-900 line-through dark:text-white">{index + 1}. {operation.name}</h6>
                      <svg fill="currentColor" className="ml-2 h-7 w-7 text-red-700 dark:text-red-600" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clipRule="evenodd" fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"></path>
                      </svg>
                    </div>
                  </div>
                  <div className="flex">
                    <button 
                      onClick={() => activeSection(`section-adas-operation-${index}`)}
                      className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                      <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path></svg>
                      <span className="sr-only">Correct</span>
                    </button>
                  </div>
                </div>
                <ul className="max-w-full list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400">
                  <li>
                    <span className="font-bold">Procedure Type: </span>{" "}
                    {operation.procedureType ? operation.procedureType : "N/A"}
                  </li>
                  <li>
                    <span className="font-bold">Repair/Installation Triggers: </span>
                    {operation.trigger ? operation.trigger : "N/A"}
                  </li>
                </ul>
                <p className="mt-4 font-normal text-gray-500 dark:text-gray-400">
                  Per the {adasReportData.vehicleName} repair manual, the{" "}
                  {operation.name} necessitates the above mentioned procedure
                  should any of the following occur:
                </p>
                <ul className="max-w-full list-inside list-decimal space-y-1 text-gray-500 dark:text-gray-400">
                  <li>The vehicle has sustained collision damage</li>
                </ul>
                {operation.externalLinks && operation.externalLinks.length > 0 && (
                  <div>
                    <p className="mt-4 font-normal text-gray-500 dark:text-gray-400">
                      OEM Procedure Requirements and Instructions:
                    </p>
                    {operation.externalLinks.map((link, index) => (
                      <div className="flex flex-row items-center ml-5 my-5" key={index}>
                        <img src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${link.url}`} alt="Manufacturer Procedure Instructions" width={100} height={100} />
                        <a href={link.url} target="_blank" rel="noreferrer" className="ml-5">
                          <span className="font-medium text-blue-600 hover:underline dark:text-blue-500">
                            {link.name}
                          </span>
                        </a>
                      </div>
                    ))}
                  </div>
                )}
                <div className="flex items-center justify-between">
                  <div className="font-small text-blue-600 hover:cursor-pointer hover:underline dark:text-blue-500">
                    &nbsp;
                  </div>
                  <div className="flex items-center justify-end space-x-5 pt-5">
                    <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                      <div className="flex rounded-lg border-transparent">
                        <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                          $
                        </span>
                        <input
                          id="price-price-ADAS Operations-Headlight Control Module-0"
                          className="bg-gray-50 w-28 p-2.5 pl-2 text-base block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                          type="number"
                        />
                      </div>
                      <span className="text-xs">Price</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            </>
          )}
          {ctOSafetyOperation && ctOSafetyOperation.map((adasOperation, index) => (
            <AddOperations
              key={index}
              adasOperation={adasOperation}
              index={index}
              adasReportDataLength={safetyOperationsCount}
              deleteAddtionalOperation={deleteAddtionalOperation}
              editAddtionalOperation={editAddtionalOperation}
              vehicleName={adasReportData.vehicleName}
            />
          ))}
        </>
      )}

      {totalSteeringOperations > 0 && (
        <>
          <div id="steering-operations" className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700">
            <h4 className="mb-2 max-w-full text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Steering Operations
            </h4>
          </div>
          {adasReportData && adasReportData.steeringOperations && adasReportData.steeringOperations.length > 0 && (
            <>
            {adasReportData.steeringOperations.map((operation, index) => (
              <div
                key={index}
                id={`section-steering-operation-${index}`}
                className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700"
              >
                <div id={`section-steering-operation-${index}-incorrect`} className="flex flex-row items-center justify-between">
                  <div className="flex flex-row">
                    <h6 className="mb-2 max-w-full text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                      {index + 1}. {operation.name}
                    </h6>
                  </div>
                  <div className="flex">
                    <button
                      onClick={() => removeSection(`section-steering-operation-${index}`)}
                      className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
                    >
                      <svg
                        fill="none"
                        className="h-4 w-4"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                      <span className="sr-only">Incorrect</span>
                    </button>
                  </div>
                </div>
                <div id={`section-steering-operation-${index}-correct`} className="flex flex-row items-center justify-between hidden">
                  <div className="flex flex-row">
                    <div className="flex space-x-4">
                      <h6 className="mb-2 max-w-full text-xl font-bold tracking-tight text-gray-900 line-through dark:text-white">{index + 1}. {operation.name}</h6>
                      <svg fill="currentColor" className="ml-2 h-7 w-7 text-red-700 dark:text-red-600" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clipRule="evenodd" fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"></path>
                      </svg>
                    </div>
                  </div>
                  <div className="flex">
                    <button 
                      onClick={() => activeSection(`section-adas-operation-${index}`)}
                      className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                      <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path></svg>
                      <span className="sr-only">Correct</span>
                    </button>
                  </div>
                </div>
                <ul className="max-w-full list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400">
                  <li>
                    <span className="font-bold">Procedure Type: </span>{" "}
                    {operation.procedureType ? operation.procedureType : "N/A"}
                  </li>
                  <li>
                    <span className="font-bold">Repair/Installation Triggers: </span>
                    {operation.trigger ? operation.trigger : "N/A"}
                  </li>
                </ul>
                <p className="mt-4 font-normal text-gray-500 dark:text-gray-400">
                  Per the {adasReportData.vehicleName} repair manual, the{" "}
                  {operation.name} necessitates the above mentioned procedure
                  should any of the following occur:
                </p>
                <ul className="max-w-full list-inside list-decimal space-y-1 text-gray-500 dark:text-gray-400">
                  <li>The vehicle has sustained collision damage</li>
                </ul>
                {operation.externalLinks && operation.externalLinks.length > 0 && (
                  <div>
                    <p className="mt-4 font-normal text-gray-500 dark:text-gray-400">
                      OEM Procedure Requirements and Instructions:
                    </p>
                    {operation.externalLinks.map((link, index) => (
                      <div className="flex flex-row items-center ml-5 my-5" key={index}>
                        <img src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${link.url}`} alt="Manufacturer Procedure Instructions" width={100} height={100} />
                        <a href={link.url} target="_blank" rel="noreferrer" className="ml-5">
                          <span className="font-medium text-blue-600 hover:underline dark:text-blue-500">
                            {link.name}
                          </span>
                        </a>
                      </div>
                    ))}
                  </div>
                )}
                <div className="flex items-center justify-between">
                  <div className="font-small text-blue-600 hover:cursor-pointer hover:underline dark:text-blue-500">
                    &nbsp;
                  </div>
                  <div className="flex items-center justify-end space-x-5 pt-5">
                    <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                      <div className="flex rounded-lg border-transparent">
                        <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                          $
                        </span>
                        <input
                          id="price-price-ADAS Operations-Headlight Control Module-0"
                          className="bg-gray-50 w-28 p-2.5 pl-2 text-base block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                          type="number"
                        />
                      </div>
                      <span className="text-xs">Price</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            </>
          )}
          {ctOSteeringOperation && ctOSteeringOperation.map((adasOperation, index) => (
            <AddOperations
              key={index}
              adasOperation={adasOperation}
              index={index}
              adasReportDataLength={steeringOperationsCount}
              deleteAddtionalOperation={deleteAddtionalOperation}
              editAddtionalOperation={editAddtionalOperation}
              vehicleName={adasReportData.vehicleName}
            />
          ))}
        </>
      )}

      {totalFunctionalOperations > 0 && (
        <>
          <div id="functional-operations" className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700">
            <h4 className="mb-2 max-w-full text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Functional Operations
            </h4>
          </div>
          {adasReportData && adasReportData.functionalOperations && adasReportData.functionalOperations.length > 0 && (
            <>
            {adasReportData.functionalOperations.map((adasOperation, index) => (
              <div
                key={index}
                id={`section-functional-operation-${index}`}
                className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700"
              >
                <div id={`section-functional-operation-${index}-incorrect`} className="flex flex-row items-center justify-between">
                  <div className="flex flex-row">
                    <h6 className="mb-2 max-w-full text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                      {index + 1}. {adasOperation.name}
                    </h6>
                  </div>
                  <div className="flex">
                    <button
                      onClick={() => removeSection(`section-functional-operation-${index}`)}
                      className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
                    >
                      <svg
                        fill="none"
                        className="h-4 w-4"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                      <span className="sr-only">Incorrect</span>
                    </button>
                  </div>
                </div>
                <div id={`section-functional-operation-${index}-correct`} className="flex flex-row items-center justify-between hidden">
                  <div className="flex flex-row">
                    <div className="flex space-x-4">
                      <h6 className="mb-2 max-w-full text-xl font-bold tracking-tight text-gray-900 line-through dark:text-white">{index + 1}. {adasOperation.name}</h6>
                      <svg fill="currentColor" className="ml-2 h-7 w-7 text-red-700 dark:text-red-600" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clipRule="evenodd" fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"></path>
                      </svg>
                    </div>
                  </div>
                  <div className="flex">
                    <button 
                      onClick={() => activeSection(`section-adas-operation-${index}`)}
                      className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                      <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path></svg>
                      <span className="sr-only">Correct</span>
                    </button>
                  </div>
                </div>
                <ul className="max-w-full list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400">
                  <li>
                    <span className="font-bold">Procedure Type: </span>{" "}
                    {adasOperation.procedureType ? adasOperation.procedureType : "N/A"}
                  </li>
                  <li>
                    <span className="font-bold">Repair/Installation Triggers: </span>
                    {adasOperation.trigger ? adasOperation.trigger : "N/A"}
                  </li>
                </ul>
                <p className="mt-4 font-normal text-gray-500 dark:text-gray-400">
                  Per the {adasReportData.vehicleName} repair manual, the{" "}
                  {adasOperation.name} necessitates the above mentioned procedure
                  should any of the following occur:
                </p>
                <ul className="max-w-full list-inside list-decimal space-y-1 text-gray-500 dark:text-gray-400">
                  {/* If trigger is not empty, split the trigger by comma and print every trigger */}
                  {adasOperation.triggerLines.map((triggerLine, index) => (
                    <li key={index}>{triggerLine}</li>
                  ))}
                </ul>
                {adasOperation.link && (
                  <div>
                    <p className="mt-4 font-normal text-gray-500 dark:text-gray-400">
                      Please consult the below manufacturer-provided documentation for
                      complete instructions and requirements:
                    </p>
                    <div>
                      <a href={adasOperation.link} target="_blank" rel="noreferrer" className="ml-5">
                        <span className="font-medium text-blue-600 hover:underline dark:text-blue-500">
                          Manufacturer Procedure Instructions
                        </span>
                      </a>
                    </div>
                    <div>
                      <a href={adasOperation.repairLink} target="_blank" rel="noreferrer" className="ml-5">
                        <span className="font-medium text-blue-600 hover:underline dark:text-blue-500">
                          Manufacturer Mandated Requirement
                        </span>
                      </a>
                    </div>
                  </div>
                )}
                <div className="flex items-center justify-between">
                  <div className="font-small text-blue-600 hover:cursor-pointer hover:underline dark:text-blue-500">
                    &nbsp;
                  </div>
                  <div className="flex items-center justify-end space-x-5 pt-5">
                    <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                      <div className="flex rounded-lg border-transparent">
                        <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                          $
                        </span>
                        <input
                          id="price-price-ADAS Operations-Headlight Control Module-0"
                          className="bg-gray-50 w-28 p-2.5 pl-2 text-base block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                          type="number"
                        />
                      </div>
                      <span className="text-xs">Price</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            </>
          )}
          {ctOFunctionalOperation && ctOFunctionalOperation.map((adasOperation, index) => (
            <AddOperations
              key={index}
              adasOperation={adasOperation}
              index={index}
              adasReportDataLength={functionalOperationsCount}
              deleteAddtionalOperation={deleteAddtionalOperation}
              editAddtionalOperation={editAddtionalOperation}
              vehicleName={adasReportData.vehicleName}
            />
          ))}
        </>
      )}

      {isSuperAdmin && (
        <>
          <div id="manual-lines" className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700">
            <h4 className="mb-2 max-w-full text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Manual Lines
            </h4>
          </div>
          {manualLines.map((manualLine, index) => (
            <div
              key={index}
              id={`section-manualines-${index}`}
              className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700"
            >
              <div id={`section-manualines-${index}-incorrect`} className="flex flex-row items-center justify-between">
                <div className="flex flex-row">
                  <h6 className="mb-2 max-w-full text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {index + 1}. {manualLine.description}
                  </h6>
                </div>
                <div className="flex">
                  <button
                    onClick={() => removeSection(`section-manualines-${index}`)}
                    className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800"
                  >
                    <svg
                      fill="none"
                      className="h-4 w-4"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                    <span className="sr-only">Incorrect</span>
                  </button>
                </div>
              </div>
              <div id={`section-manualines-${index}-correct`} className="flex flex-row items-center justify-between hidden">
                <div className="flex flex-row">
                  <div className="flex space-x-4">
                    <h6 className="mb-2 max-w-full text-xl font-bold tracking-tight text-gray-900 line-through dark:text-white">{index + 1}. {manualLine.description}</h6>
                    <svg fill="currentColor" className="ml-2 h-7 w-7 text-red-700 dark:text-red-600" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                      <path clipRule="evenodd" fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"></path>
                    </svg>
                  </div>
                </div>
                <div className="flex">
                  <button 
                    onClick={() => activeSection(`section-manualines-${index}`)}
                    className="ml-2 flex items-center justify-center text-sm font-medium text-white border border-blue-700 bg-blue-700 hover:bg-blue-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                    <svg fill="none" className="h-4 w-4" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path></svg>
                    <span className="sr-only">Correct</span>
                  </button>
                </div>
              </div>
              <ul className="max-w-full list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400">
                <li>
                  <span className="font-bold">Operation: </span>{" "}
                  {manualLine.operation ? manualLine.operation : "N/A"}
                </li>
                <li>
                  <span className="font-bold">Qty: </span>
                  {manualLine.qty ? manualLine.type : "N/A"}
                </li>
              </ul>
              <p className="mt-4 font-normal text-gray-500 dark:text-gray-400">
                Per the {adasReportData.vehicleName} repair manual, the{" "}
                {manualLine.description} necessitates the above mentioned procedure
                should any of the following occur:
              </p>
              <ul className="max-w-full list-inside list-decimal space-y-1 text-gray-500 dark:text-gray-400">
                <li>Vehicle is involved in a collision</li>
              </ul>
              <div className="flex items-center justify-between">
                <div className="font-small text-blue-600 hover:cursor-pointer hover:underline dark:text-blue-500">
                  &nbsp;
                </div>
                <div className="flex items-center justify-end space-x-5 pt-5">
                  <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                    <div className="flex rounded-lg border-transparent">
                      <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                        <Clock />
                      </span>
                      <input
                        id="price-price-ADAS Operations-Headlight Control Module-0"
                        className="bg-gray-50 w-28 p-2.5 pl-2 text-base block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                        type="number"
                        value={manualLine.totalUnit}
                      />
                    </div>
                    <span className="text-xs">Labor</span>
                  </div>
                  <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                    <div className="flex rounded-lg border-transparent">
                      <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                        <Cog />
                      </span>
                      <input
                        id="price-price-ADAS Operations-Headlight Control Module-0"
                        className="bg-gray-50 w-28 p-2.5 pl-2 text-base block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                        type="text"
                        value={manualLine.type}
                      />
                    </div>
                    <span className="text-xs">Type</span>
                  </div>
                  <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                    <div className="flex rounded-lg border-transparent">
                      <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                        $
                      </span>
                      <input
                        id="price-price-ADAS Operations-Headlight Control Module-0" 
                        className="bg-gray-50 w-28 p-2.5 pl-2 text-base block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
                        type="number"
                        value={manualLine.totalPrice}
                      />
                    </div>
                    <span className="text-xs">Price</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}

      {isSuperAdmin && (
        <>
          <div id="body-and-frame" className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow dark:border-gray-700 bg-strapi-neutral-800">
            <h4 className="mb-2 max-w-full text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Repair Procedures
            </h4>
          </div>
          <div className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow dark:border-gray-700 bg-strapi-neutral-800">
            {urlkey === 'nb011ebz-ra7u-i2cy-1dwx-3jz8kvqats6s' ? (
              <table className="min-w-full border-collapse text-white">
                <thead>
                  <tr className="bg-gray-800 bg-opacity-50">
                    <th className="py-2 px-4 border-gray-700 text-left border-r border-solid">Component</th>
                    <th className="py-2 px-4 border-gray-700 text-center border-r border-solid">Repair</th>
                    <th className="py-2 px-4 border-gray-700 text-center border-r border-solid">P&L</th>
                    <th className="py-2 px-4 border-gray-700 text-center border-r border-solid">TSB</th>
                    <th className="py-2 px-4 border-gray-700 text-center">SPEC</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3400/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Air Bag(s) Arming and Disarming</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3400/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid text-center">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/163/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Bumper</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/163/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1292/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Doors, Hood and Trunk</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1292/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1292/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">P</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/783/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Fender</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/783/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <table className="min-w-full border-collapse text-white">
                <thead>
                  <tr className="bg-gray-800 bg-opacity-50">
                    <th className="py-2 px-4 border-gray-700 text-left border-r border-solid">Component</th>
                    <th className="py-2 px-4 border-gray-700 text-center border-r border-solid">Repair</th>
                    <th className="py-2 px-4 border-gray-700 text-center border-r border-solid">P&L</th>
                    <th className="py-2 px-4 border-gray-700 text-center border-r border-solid">TSB</th>
                    <th className="py-2 px-4 border-gray-700 text-center">SPEC</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3400/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Air Bag(s) Arming and Disarming</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3400/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid text-center">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7364/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Apron / Front Fender Inner Panel</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7364/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid text-center">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3720/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Body Control Systems</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3720/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/8409/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Body Dimensions</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/8409/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/163/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Bumper</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/163/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7296/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Construction Materials</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-r text-center border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7296/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">P</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7288/filter/noFilter#" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Corrosion Protection</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7288/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">
                      <span className="font-medium text-gray-300">Cowl</span>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-r text-center border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-r text-center border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-r text-center border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-700 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 pl-8 border-r border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7232/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Cowl Moulding / Trim</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7232/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1292/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Doors, Hood and Trunk</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1292/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1292/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">P</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3821/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Exterior Moulding / Trim</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3821/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/783/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Fender</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/783/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3020/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Firewall</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3020/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">
                      <span className="font-medium text-gray-300">Frame</span>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-700 bg-opacity-50">
                    <td className="py-2 px-4 border-gray-600 pl-8 border-r border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/136/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Cross-Member</a>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/136/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-700 bg-opacity-50">
                    <td className="py-2 px-4 border-gray-600 pl-8  border-r border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/787/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Frame Rail</a>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4  border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-700 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 pl-8 border-r border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1326/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Subframe</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-r border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7203/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Information Labels</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7203/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1291/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Interior Moulding / Trim</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1298/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Locks</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/1298/filter/noFilter#" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/754/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Mirrors</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/754/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/754/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">P</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7195/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Paint, Striping and Decals</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7195/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7192/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Pillars, Rockers and Floor</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7182/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Quarter Panel</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7182/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3873/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Radiator Support</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/3873/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7178/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Rear Panel</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7178/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/6389/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Relays and Modules - Body and Frame</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/6978/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Repairs and Inspections Required After a Collision</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="#https://my.alldata.com/repair/#/vehicle/63682/component/6978/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7176/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Roof and Associated Components</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7176/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">P</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/170/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Seats</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/170/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/170/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">P</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/6401/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Sensors and Switches - Body and Frame</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">
                      <span className="font-medium text-gray-300">Spoilers, Flaps, and Air Dams</span>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-700 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 pl-8 border-r border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/165/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Spoiler</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/165/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">
                      <span className="font-medium text-gray-300">Unibody</span>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-700 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 pl-8 border-r border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/2455/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Cowl</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-600 bg-opacity-50">
                    <td className="py-2 px-4 border-gray-600 border-r border-solid">
                      <span className="font-medium text-gray-300">Weatherstrip</span>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid border-r text-center">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid text-center">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-700 bg-opacity-50">
                    <td className="py-2 px-4 border-gray-600 pl-8 border-r border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7402/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Front Door Window Glass Weatherstrip</a>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-r text-center border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7402/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-gray-600 border-r text-center border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-r text-center border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-gray-600 border-solid">&nbsp;</td>
                  </tr>
                  <tr className="bg-gray-700 bg-opacity-50">
                    <td className="py-2 px-4 border-b border-gray-600 pl-8 border-r border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7405/filter/noFilter" target="_blank" rel="noreferrer" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Rear Door Window Glass Weatherstrip</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-r text-center border-solid">
                      <a href="https://my.alldata.com/repair/#/vehicle/63682/component/7405/filter/noFilter" target="_blank" rel="noreferrer" className="text-xl text-blue-600 hover:underline dark:text-blue-500">R</a>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-600 border-r text-center border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-r text-center border-solid">&nbsp;</td>
                    <td className="py-2 px-4 border-b border-gray-600 border-solid text-center">&nbsp;</td>
                  </tr>
                </tbody>
              </table>
          )}
          </div>
        </>
      )}

      {positionStatement && positionStatement.length > 0 && (
        <div id="position-statement" className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700">
          <h4 className="mb-2 max-w-full text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Position Statement
          </h4>
          <ul className="list-decimal pl-4 mt-4">
            {positionStatement.map((statement, index) => (
              <li key={index} className="text-gray-500 dark:text-gray-400">
                <a target="_blank" rel="noreferrer" href={statement.fileUrl} className="font-medium text-blue-600 hover:underline dark:text-blue-500">
                  {statement.fileName}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="lg:mx-4 my-4 block rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 bg-strapi-neutral-800 dark:hover:bg-gray-700">
        <div className="flex flex-row items-center justify-between">
          <h4 className="mb-2 max-w-full text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Total Projected Price
          </h4>
          <div className="mr-2 flex justify-end text-lg font-semibold text-gray-500">
            $0.00
          </div>
        </div>
      </div>
      <div className={addOperation ? '' : 'hidden'}>
        <div
          className="relative z-10"
          id="headlessui-dialog-:r2t:"
          role="dialog"
          aria-modal="true"
          data-headlessui-state="open"
          aria-labelledby="headlessui-dialog-title-:r2v:"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <div
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                id="headlessui-dialog-panel-:r2u:"
                data-headlessui-state="open"
              >
                <div className="bg-white p-4">
                  <div className="flex w-full sm:flex sm:items-start">
                    <div className="w-full">
                      <h1
                        className="inline-flex w-full items-center justify-between border-b-1 pb-2 text-base font-semibold leading-6 text-gray-900"
                        id="headlessui-dialog-title-:r2v:"
                        data-headlessui-state="open"
                      >
                        <span>Add Operation</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 cursor-pointer"
                          onClick={() => setAddOperation(false)}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                          />
                        </svg>
                      </h1>
                      <div className="w-full mt-3">
                        <form id="operation-form" className="space-y-6">
                          <div className="flex-col">
                            <input type="text" id="operation_id" name="operation_id" />
                            <select 
                              onChange={(e) => setFormOperationType(e.target.value)}
                              id="operation_ct_type" 
                              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-300 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500">
                              <option value="ADAS Operations">ADAS Operation</option>
                              <option value="Safety Operations">Safety Operation</option>
                              <option value="Functional Operations">Functional Operation</option>
                            </select>
                            {formOperationType !== "Safety Operations" && (
                              <>
                                <label
                                  htmlFor="sensor"
                                  className="mb-1 mt-2 block text-sm font-medium text-gray-900 dark:text-gray-900"
                                >
                                  Sensor
                                </label>
                                <input
                                  id="sensor"
                                  className="mt-2 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400"
                                  placeholder="Millimeter Wave Radar Sensor"
                                  required=""
                                  type="text"
                                  defaultValue=""
                                  name="sensor"
                                />
                                <div className="mt-2 flex justify-between space-x-4">
                                  <div className="flex-1 flex-col">
                                    <label
                                      htmlFor="location"
                                      className="mb-1 block text-sm font-medium text-gray-900 dark:text-gray-900"
                                    >
                                      Location
                                    </label>
                                    <input
                                      id="location"
                                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400"
                                      placeholder="Grille"
                                      type="text"
                                      defaultValue=""
                                      name="location"
                                    />
                                  </div>

                                  <RadioGroup className="calibration-selection dark:text-gray-900 mt-6" labelledBy="calibration-selection" onChange={e => setSelected(e.target.value)} defaultValue={selected} name="profileSelection">
                                    <div className="flex">
                                      <Checkbox className="dark:text-gray-900" id="static" value={isStaticChecked} onChange={(e) => {handleStaticChange()}}>Static Calibration</Checkbox>
                                    </div>
                                    <div className="flex">
                                      <Checkbox className="dark:text-gray-900" id="dynamic" value={isDynamicChecked} onChange={(e) => {handleDynamicChange()}}>Dynamic Calibration</Checkbox>
                                    </div>
                                  </RadioGroup>
                                </div>
                                <label
                                  htmlFor="tools"
                                  className="mb-1 mt-2 block text-sm font-medium text-gray-900 dark:text-gray-900"
                                >
                                  Tools
                                </label>
                                <input
                                  id="tools"
                                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400"
                                  placeholder="Scan tool"
                                  type="text"
                                  defaultValue=""
                                  name="tools"
                                />
                                <label
                                  htmlFor="responsible"
                                  className="mb-1 mt-2 block text-sm font-medium text-gray-900 dark:text-gray-900"
                                >
                                  Responsible for
                                </label>
                                <input
                                  id="responsible"
                                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400"
                                  placeholder="Adaptive Cruise Control"
                                  type="text"
                                  defaultValue=""
                                  name="responsible"
                                />
                                <div className="flex-1 flex-col">
                                  <label
                                    htmlFor="part"
                                    className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-900"
                                  >
                                    Lineitems
                                  </label>
                                  <div id="lineitems-add">
                                  <div className="mb-2 mt-2 flex justify-between space-x-4 lineitems-item first-line">
                                    <input
                                      className="block w-full flex-1 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400"
                                      placeholder="Grille"
                                      type="text"
                                      name="part[]"
                                    />
                                    <input
                                      className="block w-full flex-1 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400"
                                      placeholder="Installed"
                                      type="text"
                                      name="operation[]"
                                    />
                                    <input
                                      className="block w-full flex-1 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400"
                                      placeholder={1}
                                      type="number"
                                      name="linenumber[]"
                                    />
                                    <button 
                                      type="button"
                                      onClick={() => removeFirstLine()}
                                      className="lineitems-remove  ml-2 flex items-center justify-center text-sm font-medium text-white border border-gray-300 bg-gray-300 hover:bg-gray-500 rounded-lg focus:ring-4 p-2.5 focus:outline-none focus:ring-blue-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800">
                                      <svg
                                        fill="none"
                                        className="h-5 w-5"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M6 18L18 6M6 6l12 12"
                                        />
                                      </svg>
                                      <span className="sr-only">Remove Row</span>
                                    </button>
                                  </div>
                                  </div>
                                  <button
                                    className="bg-blue-700 text-white hover:bg-blue-500 inline-flex items-center justify-center px-3 py-2 font-medium rounded-md break-words text-gray-900"
                                    type="button"
                                    onClick={() => addRow()}
                                  >
                                    <span className="">Add row</span>
                                  </button>
                                </div>
                              </>
                            )}
                            {formOperationType === "Safety Operations" && (
                              <>
                                <label
                                  htmlFor="sensor"
                                  className="mb-1 mt-2 block text-sm font-medium text-gray-900 dark:text-gray-900"
                                >
                                  Operation
                                </label>
                                <input
                                  id="sensor"
                                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400"
                                  placeholder="Collision Diagnosis"
                                  type="text"
                                  defaultValue=""
                                  name="sensor"
                                />
                                <label
                                  htmlFor="responsible"
                                  className="mb-1 mt-2 block text-sm font-medium text-gray-900 dark:text-gray-900"
                                >
                                  Procedure Type
                                </label>
                                <input
                                  id="responsible"
                                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400"
                                  placeholder="Vehicle Diagnostics"
                                  type="text"
                                  defaultValue=""
                                  name="responsible"
                                />
                              </>
                            )}
                            <label
                              htmlFor="documentation"
                              className="mb-1 mt-2 block text-sm font-medium text-gray-900 dark:text-gray-900"
                            >
                              Documentation Link
                            </label>
                            <input
                              id="documentation"
                              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400"
                              type="url"
                              defaultValue=""
                              name="documentation"
                            />
                            <div className="flex w-fit flex-col items-start">
                              <label
                                htmlFor="price"
                                className="mb-1 mt-2 block text-sm font-medium text-slate-50 dark:text-white"
                              >
                                Price
                              </label>
                              <div className="flex flex-col items-end text-gray-500 dark:text-gray-400">
                                <div className="flex rounded-lg border-transparent">
                                  <span className="inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-200 dark:border-gray-600 dark:bg-gray-600">
                                    $
                                  </span>
                                  <input
                                    id="price-add-operation-price"
                                    className="bg-gray-50 w-28 p-2.5 pl-2 text-base block h-10 rounded-r-lg border border-l-0 border-gray-300 text-center focus:outline-none dark:border-gray-300 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400"
                                    type="number"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="mt-3 w-full">
                        <Button
                          className="bg-blue-700 text-white hover:bg-blue-500 inline-flex items-center justify-center px-3 py-2 font-medium rounded-md break-words text-gray-900 w-full"
                          onClick={addOperationForm}
                          loading={isSubmittingOperation}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {shareReport && (
        <div>
          <div
            className="relative z-10"
            id="headlessui-dialog-:r2t:"
            role="dialog"
            aria-modal="true"
            data-headlessui-state="open"
            aria-labelledby="headlessui-dialog-title-:r2v:"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <div
                  className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                  id="headlessui-dialog-panel-:r2u:"
                  data-headlessui-state="open"
                >
                  <div className="bg-white p-4">
                    <div className="flex w-full sm:flex sm:items-start">
                      <div className="w-full">
                        <h1
                          className="inline-flex w-full items-center justify-between border-b-1 pb-2 text-base font-semibold leading-6 text-gray-900"
                          id="headlessui-dialog-title-:r2v:"
                          data-headlessui-state="open"
                        >
                          <span>Share Report</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                            data-slot="icon"
                            className="h-5 w-5 cursor-pointer"
                            onClick={() => setShareReport(false)}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18 18 6M6 6l12 12"
                            />
                          </svg>
                        </h1>
                        <div className="w-full mt-3">
                          <form className="space-y-6">
                            <div className="flex-col">
                              <label
                                htmlFor="sensor"
                                className="mb-1 mt-2 block text-sm font-medium text-gray-900 dark:text-gray-900"
                              >
                                Emails
                              </label>
                              <textarea
                                id="emails_share"
                                name="emails"
                                className="mt-2 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:bg-white dark:text-gray-900 dark:placeholder-gray-400"
                                placeholder="Emails"
                                required=""
                                rows="3"
                              ></textarea>
                              <div id="share-report-error" className="text-red-500 text-sm mt-2 hidden">
                                Some thing went wrong. Please try again.
                              </div>
                              <p className="dark:text-gray-500 text-sm mt-2">Ex: email1@example.com, email2@example.com</p>
                            </div>
                          </form>
                        </div>
                        <div className="mt-3 w-full">
                          <Button
                            onClick={shareEmailReport}
                            loading={isSendingEmail}
                            startIcon={<Envelop />}
                          >
                            Share Report
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {flagDeleteAddtionalOperation && (
        <div>
          <div
            className="relative z-10"
            id="headlessui-dialog-:r2t:"
            role="dialog"
            aria-modal="true"
            data-headlessui-state="open"
            aria-labelledby="headlessui-dialog-title-:r2v:"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <div
                  className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                  id="headlessui-dialog-panel-:r2u:"
                  data-headlessui-state="open"
                >
                  <div className="bg-white p-4">
                    <div className="flex w-full sm:flex sm:items-start">
                      <div className="w-full">
                        <h1
                          className="inline-flex w-full items-center justify-between border-b-1 pb-2 text-base font-semibold leading-6 text-gray-900"
                          id="headlessui-dialog-title-:r2v:"
                          data-headlessui-state="open"
                        >
                          <span>Delete Addtional Operation</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                            data-slot="icon"
                            className="h-5 w-5 cursor-pointer"
                            onClick={() => setFlagDeleteAddtionalOperation(false)}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18 18 6M6 6l12 12"
                            />
                          </svg>
                        </h1>
                        <div className="w-full mt-3">
                          <p 
                            className="text-gray-900 dark:text-gray-900 mb-4"
                          >Are you sure you want to delete this operation?</p>
                        </div>
                        <div className="mt-3 w-full">
                          <Button
                            onClick={formSubmitDeleteAddtionalOperation}
                            loading={flagDeleting}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Adas;
