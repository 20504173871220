import MitchellConection from "../components/Account/MitchellConection";
import Menu from "../components/Account/Menu";
import { Layout} from "@strapi/design-system";

export default function AccountSettingsMitchell() {
  const expandedSection = 'acc-2';

  return (
    <div>
      <Layout
        sideNav={
          <Menu 
            expandedSection={expandedSection}
          />
        }
      >
        <MitchellConection />
      </Layout>
    </div>
  );
}
