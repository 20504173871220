const labelWithKey = {
    'bonnet' : 'Bonnet',
    'front_bumper' : 'Front Bumper',
    'bumper' : 'Bumper',
    'door_front_left' : 'Door Front Left',
    'door_front_right' : 'Door Front Right',
    'door_rear_left' : 'Door Rear Left',
    'door_rear_right' : 'Door Rear Right',
    'fender_front_left' : 'Fender Front Left',
    'fender_front_right' : 'Fender Front Right',
    'fender_rear_left' : 'Fender Rear Left',
    'quarter_panel_rear_left' : 'Quarter Panel Rear Left',
    'fender_rear_right' : 'Fender Rear Right',
    'quarter_panel_rear_right' : 'Quarter Panel Rear Right',
    'headlight_left' : 'Headlight Left',
    'headlight_right' : 'Headlight Right',
    'mirror_front_left' : 'Mirror Front Left',
    'mirror_front_right' : 'Mirror Front Right',
    'rear_bumper' : 'Rear Bumper',
    'rear_windshield' : 'Rear Windshield',
    'tail_light_left' : 'Tail Light Left',
    'tail_light_right' : 'Tail Light Right',
    'trunk_cover' : 'Trunk Cover',
    'wheel_front_left' : 'Wheel Front Left',
    'wheel_front_right' : 'Wheel Front Right',
    'wheel_rear_left' : 'Wheel Rear Left',
    'wheel_rear_right' : 'Wheel Rear Right',
    'window_front_left' : 'Window Front Left',
    'window_front_right' : 'Window Front Right',
    'window_rear_left' : 'Window Rear Left',
    'window_rear_right' : 'Window Rear Right',
    'windshield' : 'Windshield',
    'quarter_window_rear_left' : 'Quarter Window Rear Left',
    'quarter_window_rear_right' : 'Quarter Window Rear Right',
    'quarter_window_front_left' : 'Quarter Window Front Left',
    'quarter_window_front_right' : 'Quarter Window Front Right',
    'running_board_left' : 'Running Board Left',
    'running_board_right' : 'Running Board Right',
    'Door-Handle' : 'Door Handle',
    'Grille' : 'Grille',
    'FogLamps-Area' : 'Fog Lamps Area',
    'Rim' : 'Rim',
    'FuelTap' : 'Fuel Tap',
    'trunk' : 'Trunk',
}

export default labelWithKey;