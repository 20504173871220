import React, { useContext, useEffect, useState } from "react";
import { GlobalContextState } from "../../context/globalContext";
import Toolbar from '../Toolbar/Toolbar';
import { ContentLayout, BaseHeaderLayout, Loader, Box, Grid, GridItem } from "@strapi/design-system";
import { Typography, Table, Thead, Tbody, Tr, Td, Th, Button } from '@strapi/design-system';
import { Searchbar, SearchForm } from '@strapi/design-system';
import { baseUrl } from "../../config";
import { Plus } from "@strapi/icons";
import DatePicker from 'react-datepicker';
import { Helmet } from "react-helmet";
import 'react-datepicker/dist/react-datepicker.css';

const getClaimByUser = `${baseUrl}/api/claim/get-user-claims`;

export default function ClaimsList() {
  const token  = useContext(GlobalContextState).token;
  const user = useContext(GlobalContextState).user;
  const uP = user.subscriptionPlan;
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalClaims, setTotalClaims] = useState(0);
  const [searchId, setSearchId] = useState('nos');
  const [searchUser, setSearchUser] = useState('nos');
  const [claimsListDataRb, setClaimsListDataRb] = useState([]);
  const [sortTcType, setSortTcType] = useState('');
  const [sortHumanType, setSortHumanType] = useState('');
  const [sortUserType, setSortUserType] = useState('');

  const [processingClaims, setProcessingClaims] = useState(false);
  const [whiteClaims, setWhiteClaims] = useState(false);
  const [estDone, setEstDone] = useState(false);
  const [revDone, setRevDone] = useState(false);
  const [adasDone, setAdasDone] = useState(false);
  const [chatDone, setChatDone] = useState(false);
  const [filterDate, setFilterDate] = useState('');
  
  useEffect(() => {
    const initFetchData = async () => {
      await fetchClaims(processingClaims, whiteClaims, estDone, revDone, adasDone, chatDone);

      if(user.superAdmin){
        const url = `${baseUrl}/api/users`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setAllUsers(data);
      }
    };

    initFetchData();
  }, [pageSize, activePage]);

  useEffect(() => {
    if(searchId.length > 3 || searchId.length === 0){
      fetchClaims(processingClaims, whiteClaims, estDone, revDone, adasDone, chatDone);
    }
  }, [searchId]);

  useEffect(() => {
    if(filterDate){
      fetchClaims(processingClaims, whiteClaims, estDone, revDone, adasDone, chatDone);
    } 
  }, [filterDate]);

  useEffect(() => {
    if(searchUser.length > 4 || searchUser.length === 0){
      fetchClaims(processingClaims, whiteClaims, estDone, revDone, adasDone, chatDone);
    }
  }, [searchUser]);

  const updatePageSize = (pSize) => {
    setPageSize(pSize);
  }

  const updateActivePage = (pActive) => {
    setActivePage(pActive);
  }

  const resetFormSearch = () => {
    setSearchId('');
    fetchClaims(processingClaims, whiteClaims, estDone, revDone, adasDone, chatDone);
  }

  const fetchProcessingClaims = async () => {
    setWhiteClaims(false);
    setEstDone(false);
    setRevDone(false);
    setAdasDone(false);
    const newStatus = !processingClaims;
    setProcessingClaims(newStatus);
    fetchClaims(newStatus, false, false, false, false, chatDone);
  }

  const fetchWhiteClaims = async () => {
    setProcessingClaims(false);
    setEstDone(false);
    setRevDone(false);
    setAdasDone(false);
    const newStatus = !whiteClaims;
    setWhiteClaims(newStatus);
    fetchClaims(false, newStatus, false, false, false, chatDone);
  }

  const fetchEstDoneClaims = async () => {
    setProcessingClaims(false);
    setWhiteClaims(false);
    setRevDone(false);
    setAdasDone(false);
    const newStatus = !estDone;
    setEstDone(newStatus);
    fetchClaims(false, false, newStatus, false, false, chatDone);
  }

  const fetchRevDoneClaims = async () => {
    setProcessingClaims(false);
    setWhiteClaims(false);
    setEstDone(false);
    setAdasDone(false);
    const newStatus = !revDone;
    setRevDone(newStatus);
    fetchClaims(false, false, false, newStatus, false, chatDone);
  }

  const fetchAdasDoneClaims = async () => {
    setProcessingClaims(false);
    setWhiteClaims(false);
    setEstDone(false);
    setRevDone(false);
    const newStatus = !adasDone;
    setAdasDone(newStatus);
    fetchClaims(false, false, false, false, newStatus, chatDone);
  }

  const fetchChatDoneClaims = async () => {
    const newStatus = !chatDone;
    setChatDone(newStatus);
    fetchClaims(processingClaims, whiteClaims, estDone, revDone, adasDone, newStatus);
  }

  async function fetchClaims(processingFt, whiteFt, estDoneFt, revDoneFt, adasDoneFt, chatDoneFt) {
    try {
      setLoading(true);
      setClaimsListDataRb([]);
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      let fetchUrl = `${getClaimByUser}?page=${activePage}&pageSize=${pageSize}`;
      if(searchId && searchId.length > 3 && searchId !== 'nos'){
        fetchUrl += `&search=${searchId}`;
      }

      if(searchUser && searchUser.length > 4 && searchUser !== 'nos'){
        fetchUrl += `&searchUser=${searchUser}`;
      }

      if(filterDate){
        // Format the date to be in the format of yyyy-mm-dd
        const date = new Date(filterDate);
        const formattedDate = date.toISOString().split('T')[0];
        fetchUrl += `&filterDate=${formattedDate}`;
      }

      if(processingFt){ fetchUrl += `&processingClaims=1`; }
      if(whiteFt){ fetchUrl += `&whiteClaims=1`; }
      if(estDoneFt){ fetchUrl += `&estDone=1`; }
      if(revDoneFt){ fetchUrl += `&revDone=1`; }
      if(adasDoneFt){ fetchUrl += `&adasDone=1`; }
      if(chatDoneFt){ fetchUrl += `&chatDone=1`; }

      const response = await fetch(fetchUrl, {
        method: 'GET',
        headers: myHeaders,
      });

      if (response.ok) {
        const data = await response.json();
        if(data){
          generateEstimateData(data.data.attributes.results);
          setTotalClaims(data.data.attributes.pagination.total);
          setPageCount(data.data.attributes.pagination.pageCount);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  const getChatMessagesCount = async (messsages) => {
    let doneSteps = [];
    if(messsages.length === 0){
      return doneSteps;
    }

    await messsages.forEach( (message) => {
      if (message.action === "upload-image" && message.type === "image") {
        doneSteps.push('upload-image');
      }
      if (message.action === "confirm-airbags") {
        doneSteps.push('confirm-airbags');
      }
      if (message.action === "confirm-steering") {
        doneSteps.push('confirm-steering');
      }
      if (message.action === "confirm-mileage") {
        doneSteps.push('confirm-mileage');
      }
      if (message.action === "confirm-vin") {
        doneSteps.push('confirm-vin');
      }
      if (message.action === "confirm-pois") {
        doneSteps.push('confirm-pois');
      }
      if (message.action === "confirm-parts") {
        doneSteps.push('confirm-parts');
      }
      if (message.action === "session-end") {
        doneSteps.push('session-end');
      }
    });

    // Remove duplicates
    doneSteps = doneSteps.filter((item, index) => doneSteps.indexOf(item) === index);

    return doneSteps;
  }

  const generateEstimateData = async (claimData) => {
    try {
      const claimsListData = [];
      const mitchellFormData = [];
      
      claimData.forEach( async (claim) => {
        let messClass = '';
        let onlyAdas = true;
        let totalSteps = 0;
        if(claim.mitchellUrlKey){
          onlyAdas = false;
          const mitchellData = {
            mitchellUrlKey: claim.mitchellUrlKey,
            mitchellId: claim.user.mitchellId
          }
          mitchellFormData.push(mitchellData);

          if(claim.ownerMsgSent){
            messClass = 'owner-msg-sent';
          }

          const checkSteps = ['upload-image', 'confirm-airbags', 'confirm-steering', 'confirm-mileage', 'confirm-vin', 'confirm-pois', 'confirm-parts', 'session-end'];
          
          const messages = claim.chat_messages;
          const doneSteps = await getChatMessagesCount(messages);
          
          // If checkSteps and doneSteps are the same, then the chat is complete
          totalSteps = doneSteps.length;
          if(doneSteps.length > 0){
            if(doneSteps.length === checkSteps.length){
              messClass = 'owner-msg-completed';
            }else {
              messClass = 'owner-msg-sent';
            }
          }
        }

        const claimColumnData = {
          id: claim.id,
          onlyAdas: onlyAdas,
          urlkey: claim.tcUrlKey,
          mitchellUrlKey: claim.mitchellUrlKey,
          vehicleVin: claim.vehicleVin,
          vehicle: claim.vehicleName,
          tcEstimate: 0,
          humanEstimate: 0,
          messComplete: 0,
          messClass: messClass,
          messStep: totalSteps,
          createdAt: new Date(claim.createdAt).toLocaleString('en-US', { timeZone: 'America/New_York', month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }),
          user: claim.user.company ? claim.user.company : claim.user.username,
          estStatus: claim.estStatus,
          revStatus: claim.revStatus,
          trailStatusCheck: '',
          trailStatusClass: '',
          claimEstimated: '',
          estimateApproved: false,
          adasStatus: claim.adasStatus
        }
        claimsListData.push(claimColumnData);
      });

      try {
        const url = `${baseUrl}/api/tc-app/get-bms-data`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ data: mitchellFormData }),
        });

        const data = await response.json();
        data.forEach( (claim) => {
          // If the claim is not found in the data, then skip it
          if(!claim){
            return;
          }

          claimsListData.forEach( async (claimlData) => {
            if(claimlData.mitchellUrlKey === claim.urlkey){
              claimlData.tcEstimate = claim.dataTcBms;
              claimlData.trailStatusCheck = claim.trailStatusCheck;
              claimlData.trailStatusClass = claim.trailStatusClass;
              claimlData.claimEstimated = claim.claimEstimated;
              if(claim.dataHumanBms !== 0){
                claimlData.estimateApproved = true;
              }
              if(claim.dataHumanBms === 0 && claim.claimEstimated !== ''){
                claimlData.humanEstimate = claim.claimEstimated;
              }else {
                claimlData.humanEstimate = '$' + claim.dataHumanBms;
              }
            }
          });
        });
      } catch (error) {
        console.error(error);
      }

      setClaimsListDataRb(claimsListData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const processStatusMap = {
    pending: "pending",
    created: "pending",
    processing: "processing",
    mitchell_estimating: "processing",
    completed: "completed",
    failed: "failed",
  };

  const sortByTcEstimate = () => {
    setSortHumanType('');
    setSortUserType('');
    if(sortTcType === 'desc'){
      const sortedData = claimsListDataRb.sort((a, b) => a.tcEstimate - b.tcEstimate);
      setClaimsListDataRb([...sortedData]);
      setSortTcType('asc');
    }else{
      const sortedData = claimsListDataRb.sort((a, b) => b.tcEstimate - a.tcEstimate);
      setClaimsListDataRb([...sortedData]);
      setSortTcType('desc');
    }
  };
  
  const sortByHumanEstimate = () => {
    setSortTcType('');
    setSortUserType('');
    if(sortHumanType === 'desc'){
      const sortedData = claimsListDataRb.sort((a, b) => a.humanEstimate - b.humanEstimate);
      setClaimsListDataRb([...sortedData]);
      setSortHumanType('asc');
    }else{
      const sortedData = claimsListDataRb.sort((a, b) => b.humanEstimate - a.humanEstimate);
      setClaimsListDataRb([...sortedData]);
      setSortHumanType('desc');
    }
  };

  const sortByUser = () => {
    setSortTcType('');
    setSortHumanType('');
    if(sortUserType === 'desc'){
      const sortedData = claimsListDataRb.sort((a, b) => a.user.localeCompare(b.user));
      setClaimsListDataRb([...sortedData]);
      setSortUserType('asc');
    }else{
      const sortedData = claimsListDataRb.sort((a, b) => b.user.localeCompare(a.user));
      setClaimsListDataRb([...sortedData]);
      setSortUserType('desc');
    }
  };

  const handleDateFilter = (date) => {
    setFilterDate(date);
  }

  const generateEstimate = async (estimateUrlKey) => {
    if(!user.superAdmin){
      return;
    }

    const requestUrl = `${baseUrl}/api/claim/start-estimate`;
    const requestBody = {
      estimateUrlKey: estimateUrlKey,
      strapiToken: token
    };
    await fetch(requestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    });
  }

  const applyAllowance = async (estimateUrlKey) => {
    if(!user.superAdmin){
      return;
    }
  
    const requestUrl = `${baseUrl}/api/allowance/apply-rules`;
    const requestBody = {
      claimId: estimateUrlKey,
      strapiToken: token
    };

    await fetch(requestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    }).then((response) => response.json())
    .then((data) => {
      if (data.status && data.status === 'error') {
        if (data.code === 'estimate_processing') {
          alert('Processing EST for this claim. Please try again later');
        }
        if (data.code === 'allowance_processing') {
          alert('Processing REV for this claim. Please try again later');
        }
        if (data.code === 'adas_processing') {
          alert('Processing ADAS for this claim. Please try again later');
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }
  
  const triggerAdas = async (estimateUrlKey) => {
    if(!user.superAdmin){
      return;
    }
    
    const requestUrl = `${baseUrl}/api/allowance/trigger-manual-adas`;
    const requestBody = {
      claimId: estimateUrlKey,
      strapiToken: token
    };

    await fetch(requestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    }).then((response) => response.json())
      .then((data) => {
        if (data.status && data.status === 'error') {
          alert(data.message)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const openChatLink = (urlKey) => {
    const chatUrl = `https://chat.trueclaim.ai/?claimId=${urlKey}`;
    window.open(chatUrl, '_blank');
  }

  const handleCreateClaim = () => {
    // Change the url to the create claim page
    window.location.href = '/claims/create';
  }
  
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trueclaim App - Claims</title>
      </Helmet>
      <div className="base-header-layout">
        <BaseHeaderLayout
          title="Claims"
          subtitle={totalClaims + " entries found"}
          as="h2"
          primaryAction={
            <>
            <Button
              onClick={handleCreateClaim}
              startIcon={<Plus />}
              className="button-new-claim"
            >
              New Claim
            </Button>
            <Button
              onClick={() => fetchClaims(processingClaims, whiteClaims, estDone, revDone, adasDone, chatDone)}
              className="btn-refresh"
            >
              Refresh
            </Button>
            </>
          }
        />
      </div>
      <div className="base-header-layout">
      <ContentLayout>
        <Box className="loading-center list-claims" color="neutral800" padding={4} background="neutral0">
          <Grid className="mb-fw-ct z-20 relative" gap={3}>
            <GridItem className="mb-fw filter-claims" col="12">
              <SearchForm>
                <Searchbar name="searchbar" onClear={() => resetFormSearch()} value={searchId !== 'nos' ? searchId : ''} onChange={e => setSearchId(e.target.value)} clearLabel="Clearing the plugin search" placeholder="Search">
                  Search
                </Searchbar>
              </SearchForm>

              <DatePicker
                showIcon
                selected={filterDate}
                onChange={date => handleDateFilter(date)}
                className="custom-datepicker"
              />
              
              <button className={`btn btn-primary btn-filter ${loading ? 'disabled' : ''} ${processingClaims ? 'active' : ''}`}
                      onClick={() => fetchProcessingClaims()}>Processing</button>
              <button className={`btn btn-primary btn-filter ${loading ? 'disabled' : ''} ${whiteClaims ? 'active' : ''}`}
                      onClick={() => fetchWhiteClaims()}>Unprocessed</button>
                      <button className={`btn btn-primary small ${loading ? 'disabled' : ''} ${chatDone ? 'active' : ''}`}
                              onClick={() => fetchChatDoneClaims()}>CHAT</button>
              {uP !== 'PostEstimate' ? (
                <button className={`btn btn-primary small ${loading ? 'disabled' : ''} ${estDone ? 'active' : ''}`}
                      onClick={() => fetchEstDoneClaims()}>EST</button>
              ) : (
                <button className={`btn btn-primary small disabled`}>EST</button>
              )}

              {uP !== 'PreEst' ? (
                <button className={`btn btn-primary small ${loading ? 'disabled' : ''} ${revDone ? 'active' : ''}`}
                  onClick={() => fetchRevDoneClaims()}>REV</button>
              ) : (
                <button className={`btn btn-primary small disabled`}>REV</button>
              )}

              {uP !== 'PreEst' ? (
                <button className={`btn btn-primary small ${loading ? 'disabled' : ''} ${adasDone ? 'active' : ''}`}
                onClick={() => fetchAdasDoneClaims()}>ADAS</button>
              ) : (
                <button className={`btn btn-primary small disabled`}>ADAS</button>
              )}
            </GridItem>
            {user.superAdmin && (
              <GridItem className="mb-fw search-user-form" col="12">
                <select className="select-user" value={searchUser} onChange={e => setSearchUser(e.target.value)}>
                  <option value="nos">Select User</option>
                  {allUsers && allUsers.map((userFilter) => (
                    !userFilter.superAdmin && (
                      <option key={userFilter.id} value={userFilter.username}>{`${userFilter.username} - ${userFilter.company}`}</option>
                    )
                  ))}
                </select>
              </GridItem>
            )}
          </Grid>
          {loading ? (
            <Loader/>
          ) : (
            <div className="table-responsive-block z-10 mt-4 table-sticky-header">
              <Table colCount={6} rowCount={claimsListDataRb.length}>
                <Thead>
                  <Tr>
                    <Th>
                      <Typography variant="sigma">Vehicle</Typography>
                    </Th>
                    <Th className="cursor-n-resize" onClick={() => sortByTcEstimate()}>
                      <Typography variant="sigma">Tc Estimate</Typography>
                    </Th>
                    <Th className="cursor-n-resize" onClick={() => sortByHumanEstimate()}>
                      <Typography variant="sigma" >Human Estimate</Typography>
                    </Th>
                    {user.superAdmin && (
                      <Th className="cursor-n-resize" onClick={() => sortByUser()}>
                        <Typography variant="sigma">Garage</Typography>
                      </Th>
                    )}
                    <Th>
                      <Typography variant="sigma">Created At</Typography>
                    </Th>
                    <Th className="colum-action">
                      <Typography variant="sigma">Trueclaim</Typography>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {claimsListDataRb.map((claim, index) => (
                    <Tr key={claim.id}>
                      <Td data-label="Vehicle">
                        <a href={`/claims/claim-details/${claim.urlkey}`}>
                          <Typography textColor="neutral800">{claim.vehicle && claim.vehicle !== '0 null null' ? claim.vehicle : 'No Vehicle Selected'}</Typography>
                          {claim.vehicleVin && (
                            <span> - <Typography textColor="neutral800">VIN: {claim.vehicleVin && claim.vehicleVin !== 'null' ? claim.vehicleVin : 'N/A'}</Typography></span>
                          )}
                          <span className={`text-wrap text-sm text-center block ${claim.trailStatusClass}`}>{claim.trailStatusCheck ? claim.trailStatusCheck : "N/A"} {claim.claimEstimated ? `- (${claim.claimEstimated})` : ''}</span>
                        </a>
                      </Td>
                      <Td className="py-1" data-label="TC Estimate">
                        <span className={claim.tcEstimate !== 0 ? "text-green-600" : "text-slate-300"}>${claim.tcEstimate}</span>
                      </Td>
                      <Td className="py-1" data-label="Human Estimate">
                        <span className={claim.estimateApproved ? "text-green-600" : "text-slate-300"}>{claim.humanEstimate}</span>
                      </Td>
                      {user.superAdmin && (
                        <Td className="py-1" data-label="Garage">
                          <span className="text-wrap text-sm">{claim.user}</span>
                        </Td>
                      )}
                      <Td className="py-1" data-label="Created At">
                        <Typography className="date-width" textColor="neutral800">{claim.createdAt}</Typography>
                      </Td>
                      <Td className="no-label py-1">
                        <div className="list-action-tc">
                          <div className="trueclaim-col-actions">
                            <span onClick={() => openChatLink(claim.mitchellUrlKey)} className={`tc-actions-item chat ${claim.messClass}`}>CHAT {claim.messStep > 0 && (`(${claim.messStep}/8)`)}</span>
                            {uP !== 'PostEstimate' ? (
                              <span onClick={() => generateEstimate(claim.urlkey)} className={`tc-actions-item generate-estimate ${claim.estStatus ? processStatusMap[claim.estStatus] : ''}`}>EST</span>
                            ) : (
                              <span className={`tc-actions-item generate-estimate disabled`}>EST</span>
                            )}
                            {uP !== 'PreEst' ? (
                              <span onClick={() => applyAllowance(claim.urlkey)} className={`tc-actions-item apply-allowance ${claim.revStatus ? processStatusMap[claim.revStatus] : ''}`}>REV</span>
                            ) : (
                              <span className={`tc-actions-item apply-allowance disabled`}>REV</span>
                            )}
                            {uP !== 'PreEst' ? (
                              <span onClick={() => triggerAdas(claim.urlkey)} className={`tc-actions-item trigger-adas ${claim.adasStatus ? processStatusMap[claim.adasStatus] : ''}`}>ADAS</span>
                            ) : (
                              <span className={`tc-actions-item trigger-adas disabled`}>ADAS</span>
                            )}
                          </div>
                        </div>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Toolbar pageCount={pageCount} 
                      activePage={activePage}
                      updatePageSize={updatePageSize}
                      pageSize={pageSize}
                      updateActivePage={updateActivePage}
              />
            </div>
          )}
        </Box>
      </ContentLayout>
      </div>
    </div>
  );
}