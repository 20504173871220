import React, { useState, useContext, useEffect } from "react";
import { GlobalContextState } from "../../context/globalContext";
import { Button, TextInput, BaseHeaderLayout, ContentLayout, Switch, Typography, 
  Box,
  RadioGroup,
  Radio,
  Checkbox,
  Tooltip
} from '@strapi/design-system';
import { Check, Information } from '@strapi/icons';
import { baseUrl } from "../../config";
import qs from 'qs';
import { LoadingSpinner } from '../ui/loading-spinner';

export default function Config() {
  const [activated, setActivated] = useState(false);
  const [apActivated, setSpActivated] = useState(false);

  const configUrl = `${baseUrl}/api/configurations`;
  const token = useContext(GlobalContextState).token;
  const user = useContext(GlobalContextState).user;
  const uP = user.subscriptionPlan;
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loading, setLoading] = useState(true);

  const [msgEnableId, setMsgEnableId] = useState();
  const [msgAppointmentId, setMsgAppointmentId] = useState();
  const [msgEstimatorNumberId, setMsgEstimatorNumberId] = useState();
  const [msgEstimatorEmailId, setMsgEstimatorEmailId] = useState();
  const [estRrAlwaysNewPartsId, setEstRrAlwaysNewPartsId] = useState();
  const [estAutoWriteId, setEstAutoWriteId] = useState();
  const [adasAutoWriteOnManualId, setAdasAutoWriteOnManualId] = useState();

  const [formData, setFormData] = useState({
    MSG_ENABLE: '0',
    MSG_APPOINTMENT: '0',
    MSG_ESTIMATOR_NUMBER: '',
    MSG_ESTIMATOR_EMAIL: '',
    EST_RR_ALWAYS_NEW_PARTS: "0",
    EST_AUTO_WRITE: "1",
    ADAS_AUTO_WRITE_ON_MANUAL: "1",
  });

  useEffect(() => {
    const initFetchData = async () => {
      await fetchFormData();
    };

    initFetchData();
  }, []);

  useEffect(() => {
    setFormData({ ...formData, MSG_ENABLE: activated ? '1' : '0' });
  }, [activated]);

  useEffect(() => {
    setFormData({ ...formData, MSG_APPOINTMENT: apActivated ? '1' : '0' });
  }, [apActivated]);

  const updatePhoneNumber = (e) => {
    setFormData({ ...formData, MSG_ESTIMATOR_NUMBER: e.target.value });
  }

  const updateEstimatorEmail = (e) => {
    setFormData({ ...formData, MSG_ESTIMATOR_EMAIL: e.target.value });
  }

  async function handleSubmit() {
    setLoadingSubmit(true);
    try {
      if(msgEnableId){
        await updateConfiguration('MSG_ENABLE', formData.MSG_ENABLE, msgEnableId);
      } else {
        await createNewConfiguration('MSG_ENABLE', formData.MSG_ENABLE);
      }

      if(msgAppointmentId){
        await updateConfiguration('MSG_APPOINTMENT', formData.MSG_APPOINTMENT, msgAppointmentId);
      } else {
        await createNewConfiguration('MSG_APPOINTMENT', formData.MSG_APPOINTMENT);
      }

      if(msgEstimatorNumberId){
        await updateConfiguration('MSG_ESTIMATOR_NUMBER', formData.MSG_ESTIMATOR_NUMBER, msgEstimatorNumberId);
      } else {
        await createNewConfiguration('MSG_ESTIMATOR_NUMBER', formData.MSG_ESTIMATOR_NUMBER);
      }

      if(msgEstimatorEmailId){
        await updateConfiguration('MSG_ESTIMATOR_EMAIL', formData.MSG_ESTIMATOR_EMAIL, msgEstimatorEmailId);
      } else {
        await createNewConfiguration('MSG_ESTIMATOR_EMAIL', formData.MSG_ESTIMATOR_EMAIL);
      }

      if(estRrAlwaysNewPartsId){
        await updateConfiguration('EST_RR_ALWAYS_NEW_PARTS', formData.EST_RR_ALWAYS_NEW_PARTS, estRrAlwaysNewPartsId);
      } else {
        await createNewConfiguration('EST_RR_ALWAYS_NEW_PARTS', formData.EST_RR_ALWAYS_NEW_PARTS);
      }

      if(estAutoWriteId){
        await updateConfiguration('EST_AUTO_WRITE', formData.EST_AUTO_WRITE, estAutoWriteId);
      } else {
        await createNewConfiguration('EST_AUTO_WRITE', formData.EST_AUTO_WRITE);
      }

      if(adasAutoWriteOnManualId){
        await updateConfiguration('ADAS_AUTO_WRITE_ON_MANUAL', formData.ADAS_AUTO_WRITE_ON_MANUAL, adasAutoWriteOnManualId);
      } else {
        await createNewConfiguration('ADAS_AUTO_WRITE_ON_MANUAL', formData.ADAS_AUTO_WRITE_ON_MANUAL);
      }

      await fetchFormData();
    } catch (error) {
      setLoadingSubmit(false);
      console.error('Error:', error);
    }
  }

  async function updateConfiguration($code, $value, $id) {
    if($id){
      const newFormData = {
        configCode: $code,
        configValue: String($value),
      };
  
      const updateUrl = `${configUrl}/${$id}`;
      const response = await fetch(updateUrl, {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ data: newFormData }),
      });
  
      return response;
    }
  }

  async function createNewConfiguration($code, $value) {
    const newFormData = {
      configCode: $code,
      configValue: String($value),
      user: user.id,
    };

    const response = await fetch(configUrl, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ data: newFormData }),
    });

    return response;
  }

  async function fetchFormData() {
    try {
      setLoadingSubmit(false);
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);
      
      const query = qs.stringify({
        filters: {
          user: {
            username: {
              $eq: user.username,
            },
          },
        },
      }, {
        encodeValuesOnly: true,
      });

      const fetchUrl = `${configUrl}?${query}`;
      const response = await fetch(fetchUrl, {
        method: 'GET',
        headers: myHeaders,
      });

      if (response.ok) {
        const data = await response.json();
        if(data){
          const configData = data.data;
          const fetchedFormData = {
            MSG_ENABLE: '0',
            MSG_APPOINTMENT: '0',
            MSG_ESTIMATOR_NUMBER: '',
            MSG_ESTIMATOR_EMAIL: '',
            EST_RR_ALWAYS_NEW_PARTS: "0",
            EST_AUTO_WRITE: "1",
            ADAS_AUTO_WRITE_ON_MANUAL: "1",
          };
          configData.forEach((config) => {
            if(config.attributes.configCode === 'MSG_ENABLE'){
              setActivated(config.attributes.configValue === '1');
              setMsgEnableId(config.id);
            }
            if(config.attributes.configCode === 'MSG_APPOINTMENT'){
              setSpActivated(config.attributes.configValue === '1');
              setMsgAppointmentId(config.id);
            }
            if(config.attributes.configCode === 'MSG_ESTIMATOR_NUMBER'){
              fetchedFormData.MSG_ESTIMATOR_NUMBER = config.attributes.configValue; 
              setMsgEstimatorNumberId(config.id);
            }
            if(config.attributes.configCode === 'MSG_ESTIMATOR_EMAIL'){
              fetchedFormData.MSG_ESTIMATOR_EMAIL = config.attributes.configValue;
              setMsgEstimatorEmailId(config.id);
            }
            if(config.attributes.configCode === 'EST_RR_ALWAYS_NEW_PARTS'){
              fetchedFormData.EST_RR_ALWAYS_NEW_PARTS = config.attributes.configValue;
              setEstRrAlwaysNewPartsId(config.id);
            }

            if(config.attributes.configCode === 'EST_AUTO_WRITE'){
              fetchedFormData.EST_AUTO_WRITE = config.attributes.configValue;
              setEstAutoWriteId(config.id);
            }

            if(config.attributes.configCode === 'ADAS_AUTO_WRITE_ON_MANUAL'){
              fetchedFormData.ADAS_AUTO_WRITE_ON_MANUAL = config.attributes.configValue;
              setAdasAutoWriteOnManualId(config.id);
            }
          });
          setFormData(fetchedFormData);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  }
  const [selected, setSelected] = useState();
  const [ruleSettings, setRuleSettings] = useState({
    stateRule: false,
    carrierRule: false,
    customRule: false
  });

  function updateRuleSettings(index, value) {
    setRuleSettings({ ...ruleSettings, [index]: value });
  }

  return (
    <div>
      <BaseHeaderLayout title="Configuration" as="h2" primaryAction={<Button onClick={handleSubmit}
                                                                                  loading={loadingSubmit && true}
                                                                                  startIcon={<Check />}>Save</Button>} />
      <ContentLayout>
        {uP !== 'PostEstimate' ? (
        <Box color="neutral800" padding={6} background="neutral0">
          <Typography variant="beta" fontWeight="bold">PRE-Estimate Automations</Typography>
          <div className="max-w-md mt-4">
            <Box marginBottom={2}>
              <Typography variant="pi" fontWeight="bold">Capture Damage Info</Typography>
            </Box>
            <Box marginBottom={6}>
              <Switch label="Capture Damage Info" selected={activated} onChange={() => setActivated(s => !s)} visibleLabels />
            </Box>

            <Box marginBottom={2}>
              <Typography variant="pi" fontWeight="bold">Capture Appointment Info</Typography>
            </Box>
            <Box marginBottom={6}>
              <Switch label="Capture Appointment Info" selected={apActivated} onChange={() => setSpActivated(s => !s)} visibleLabels />
            </Box>
            <Box marginBottom={6}>
              <TextInput type="text" 
                        label="Estimator Phone Number" 
                        name="phoneNumber" 
                        onChange={updatePhoneNumber}
                        value={formData.MSG_ESTIMATOR_NUMBER}
                        id="phoneNumber" />
            </Box>
            <Box marginBottom={0}>
              <TextInput type="email" 
                        label="Estimator Email" 
                        name="estimatorEmail"
                        onChange={updateEstimatorEmail}
                        value={formData.MSG_ESTIMATOR_EMAIL}
                        id="estimatorEmail" />
            </Box>
            
          </div>
        </Box>
        ) : (
          <Box className="cursor-not-allowed" color="neutral800" padding={6} background="neutral0">
            <Typography variant="beta" fontWeight="bold">PRE-Estimate Automations</Typography>
            <div className="max-w-md mt-4">
              <Box marginBottom={2}>
                <Typography variant="pi" fontWeight="bold">Capture Damage Info</Typography>
              </Box>
              <Box marginBottom={6}>
                <Switch label="Capture Damage Info" disabled visibleLabels />
              </Box>

              <Box marginBottom={2}>
                <Typography variant="pi" fontWeight="bold">Capture Appointment Info</Typography>
              </Box>
              <Box marginBottom={6}>
                <Switch label="Capture Appointment Info" disabled visibleLabels />
              </Box>
              <Box marginBottom={6}>
                <TextInput type="text" 
                          label="Estimator Phone Number" 
                          name="phoneNumber" 
                          disabled
                          id="phoneNumber" />
              </Box>
              <Box marginBottom={0}>
                <TextInput type="email" 
                          label="Estimator Email" 
                          name="estimatorEmail"
                          disabled
                          id="estimatorEmail" />
              </Box>
              
            </div>
          </Box>
        )}

        {uP !== 'PreEst' ? (
          <Box className="mt-8" color="neutral800" padding={6} background="neutral0">
            <Typography variant="beta" fontWeight="bold">POST-Estimate Automations</Typography>
            <div className="max-w-md mt-4">
              <Box marginBottom={2}>
                <Typography variant="pi" fontWeight="bold">
                  On Remove/Replace always use new parts
                </Typography>
              </Box>
              <Box marginBottom={6}>
                <Switch
                  label="On Remove/Replace always use new parts"
                  selected={formData.EST_RR_ALWAYS_NEW_PARTS === "1"}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      EST_RR_ALWAYS_NEW_PARTS:
                        formData.EST_RR_ALWAYS_NEW_PARTS === "1" ? "0" : "1",
                    })
                  }
                  visibleLabels
                />
              </Box>
              <Box marginBottom={2}>
                <Typography variant="pi" fontWeight="bold">
                  Auto Write Estimate
                </Typography>
              </Box>
              <Box marginBottom={6}>
                <Switch
                  label="Auto Write"
                  selected={formData.EST_AUTO_WRITE === "1"}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      EST_AUTO_WRITE:
                        formData.EST_AUTO_WRITE === "1" ? "0" : "1",
                    })
                  }
                  visibleLabels
                />
              </Box>

              <Box marginBottom={6}>
                <Typography variant="pi" fontWeight="bold">
                  General Profile Selection
                </Typography>
                <RadioGroup labelledBy="profile-selection" onChange={e => setSelected(e.target.value)} value={selected} name="profileSelection">
                  <div className="mt-4"><Radio value="upwriting">Up-writing</Radio></div>
                  <div className="mt-4"><Radio value="downwriting">Down-writing</Radio></div>
                </RadioGroup>
              </Box>

              <Box >
                <Typography variant="pi" fontWeight="bold">
                  Rules
                </Typography>
                <RadioGroup labelledBy="profile-selection" onChange={e => setSelected(e.target.value)} value={selected} name="profileSelection">
                  <div className="mt-4 flex">
                    <Checkbox value={ruleSettings['stateRule']} onChange={(e) => {updateRuleSettings('stateRule', e.target.checked)}}>Apply State Rules</Checkbox>
                    <Tooltip description="This will apply the rules of the state found in insured address"><Information className="icon-tooltip" /></Tooltip>
                  </div>
                  <div className="mt-4 flex">
                    <Checkbox value={ruleSettings['carrierRule']} onChange={(e) => {updateRuleSettings('carrierRule', e.target.checked)}}>Apply Insurrance Carrier Rules</Checkbox>
                    <Tooltip description="This will apply the rules of the insurrance carrier found in the claim"><Information className="icon-tooltip" /></Tooltip>
                  </div>
                  <div className="mt-4 flex">
                    <Checkbox value={ruleSettings['customRule']} onChange={(e) => {updateRuleSettings('customRule', e.target.checked)}}>Apply Custom Rules</Checkbox>
                    <Tooltip description="This will apply custom defined rules - See Documentation"><Information className="icon-tooltip" /></Tooltip>
                  </div>
                  {ruleSettings['customRule'] && (
                    <>
                      <div className="mt-4">
                        <input type="file" />
                      </div>
                    </>
                  )}
                </RadioGroup>
              </Box>
            </div>
          </Box>
        ) : (
          <Box className="mt-8 cursor-not-allowed" color="neutral800" padding={6} background="neutral0">
            <Typography variant="beta" fontWeight="bold">POST-Estimate Automations</Typography>
            <div className="max-w-md mt-4">
              <Box marginBottom={2}>
                <Typography variant="pi" fontWeight="bold">
                  On Remove/Replace always use new parts
                </Typography>
              </Box>
              <Box marginBottom={6}>
                <Switch
                  label="On Remove/Replace always use new parts"
                  disabled
                  visibleLabels
                />
              </Box>
              <Box marginBottom={2}>
                <Typography variant="pi" fontWeight="bold">
                  Auto Write Estimate
                </Typography>
              </Box>
              <Box marginBottom={6}>
                <Switch
                  label="Auto Write"
                  disabled
                  visibleLabels
                />
              </Box>

              <Box marginBottom={6}>
                <Typography variant="pi" fontWeight="bold">
                  General Profile Selection
                </Typography>
                <RadioGroup labelledBy="profile-selection" disabled name="profileSelection">
                  <div className="mt-4"><Radio value="upwriting">Up-writing</Radio></div>
                  <div className="mt-4"><Radio value="downwriting">Down-writing</Radio></div>
                </RadioGroup>
              </Box>

              <Box >
                <Typography variant="pi" fontWeight="bold">
                  Rules
                </Typography>
                <RadioGroup labelledBy="profile-selection" disabled name="profileSelection">
                  <div className="mt-4 flex">
                    <Checkbox>Apply State Rules</Checkbox>
                    <Tooltip description="This will apply the rules of the state found in insured address"><Information className="icon-tooltip" /></Tooltip>
                  </div>
                  <div className="mt-4 flex">
                    <Checkbox>Apply Insurrance Carrier Rules</Checkbox>
                    <Tooltip description="This will apply the rules of the insurrance carrier found in the claim"><Information className="icon-tooltip" /></Tooltip>
                  </div>
                  <div className="mt-4 flex">
                    <Checkbox>Apply Custom Rules</Checkbox>
                    <Tooltip description="This will apply custom defined rules - See Documentation"><Information className="icon-tooltip" /></Tooltip>
                  </div>
                </RadioGroup>
              </Box>
            </div>
          </Box>
        )}
      
      {uP !== 'PreEst' ? (
        <Box className="mt-8" color="neutral800" padding={6} background="neutral0">
          <Typography variant="beta" fontWeight="bold">ADAS</Typography>
          <div className="max-w-md mt-4">
            <Box marginBottom={2}>
              <Typography variant="pi" fontWeight="bold">
                Auto Write ADAS Lines
              </Typography>
            </Box>
            <Box>
              <Switch
                label="Auto Write ADAS Lines"
                selected={formData.ADAS_AUTO_WRITE_ON_MANUAL === "1"}
                onChange={() =>
                  setFormData({
                    ...formData,
                    ADAS_AUTO_WRITE_ON_MANUAL:
                      formData.ADAS_AUTO_WRITE_ON_MANUAL === "1" ? "0" : "1",
                  })
                }
                visibleLabels
              />
            </Box>
          </div>
        </Box>
      ) : (
        <Box className="mt-8 cursor-not-allowed" color="neutral800" padding={6} background="neutral0">
          <Typography variant="beta" fontWeight="bold">ADAS</Typography>
          <div className="max-w-md mt-4">
            <Box marginBottom={2}>
              <Typography variant="pi" fontWeight="bold">
                Auto Write ADAS Lines
              </Typography>
            </Box>
            <Box>
              <Switch
                label="Auto Write ADAS Lines"
                disabled
                visibleLabels
              />
            </Box>
          </div>
        </Box>
      )}
        
      </ContentLayout>
    </div>
  );
}