import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import GlobalContextProvider from "./context/globalContext";
import { BrowserRouter } from "react-router-dom";
import { DesignSystemProvider, darkTheme } from '@strapi/design-system';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <GlobalContextProvider>
    <BrowserRouter>
      <DesignSystemProvider locale="en-US" theme={darkTheme}>
        <App />
      </DesignSystemProvider>
    </BrowserRouter>
  </GlobalContextProvider>
);
