import { Switch, Route, Redirect } from "react-router-dom";
import React from "react";
import SideNav from "../components/SideNav/SideNav";
import ActiveAccount from "./ActiveAccount";
import AccountSubscription from "./AccountSubscription";
import AccountSubscriptionChange from "./AccountSubscriptionChange";
import AccountSettings from "./AccountSettings";
import AccountSettingsUpdateProfile from "./AccountSettingsUpdateProfile";
import AccountSettingsUpdatePassword from "./AccountSettingsUpdatePassword";
import AccountSettingsMitchell from "./AccountSettingsMitchell";
import AccountSettingsIntergrations from "./Account/AccountSettingsIntergrations";
import { Layout, Box } from "@strapi/design-system";
import { Helmet } from "react-helmet";

function SettingView() {
  return <Redirect to="/settings/update-profile" />;
}

export default function Settings() {

  return (
    <Box background="neutral100">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trueclaim App - Settings</title>
      </Helmet>
      <Layout sideNav={<SideNav />}>
        <main className="flex-1">
          <Switch>
            <Route exact path="/settings" render={() => <SettingView />} />
            <Route
              path="/settings/subscription"
              render={() => <AccountSubscription />}
            />
            <Route
              path="/settings/subscription-change"
              render={() => <AccountSubscriptionChange />}
            />
            <Route
              path="/settings/configurations"
              render={() => <AccountSettings />}
            />
            <Route
              path="/settings/update-profile"
              render={() => <AccountSettingsUpdateProfile />}
            />
            <Route
              path="/settings/update-password"
              render={() => <AccountSettingsUpdatePassword />}
            />
            <Route
              path="/settings/active-account"
              render={() => <ActiveAccount />}
            />
            <Route
              path="/settings/config-mitchell"
              render={() => <AccountSettingsMitchell />}
            />
            <Route
              path="/settings/config-progi-parts"
              render={() => <AccountSettingsIntergrations typeAccount="progiParts" />}
            />
            <Route
              path="/settings/config-progi-sync"
              render={() => <AccountSettingsIntergrations typeAccount="progiSync" />}
            />
            <Route
              path="/settings/config-audatex"
              render={() => <AccountSettingsIntergrations typeAccount="audatex" />}
            />
            <Route
              path="/settings/config-ccc"
              render={() => <AccountSettingsIntergrations typeAccount="ccc" />}
            />
            <Route
              path="/settings/config-parts-trader"
              render={() => <AccountSettingsIntergrations typeAccount="partsTrader" />}
            />
            <Route
              path="/settings/config-repair-center"
              render={() => <AccountSettingsIntergrations typeAccount="repairCenter" />}
            />
            <Route
              path="/settings/config-alldata"
              render={() => <AccountSettingsIntergrations typeAccount="alldata" />}
            />
          </Switch>
        </main>
      </Layout>
    </Box>
  );
}
