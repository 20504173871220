import { useContext } from "react";
import { GlobalContextState } from '../context/globalContext';
import { Redirect } from 'react-router-dom';

import SignupForm from "../components/SignupForm/SignupForm";
import { Box } from "@strapi/design-system";

export default function BodyShopRegister() {
  const { loggedIn } = useContext(GlobalContextState);
  
  if (loggedIn) return <Redirect to="/claims" />;
  
  return (
    <>
      <Box className="h-full" paddingTop={2} paddingBottom={11}>
        <SignupForm />
      </Box>
    </>
  );
}
