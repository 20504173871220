import React from 'react';
import { RawTable, RawTd, RawTr, RawTbody, Box, TabPanel } from "@strapi/design-system";
import { default as MuiAccordion } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingSpinner as LoadingSpinner1 } from "../ui/loading-spinner";

const ClaimDetailLogs = ({ isProcessLogsLoading, processLogsData }) => {
  
  function formatLogDate(date){
    let formatted = new Date(date).toLocaleString("en-GB", {
      timeZone: "Canada/Eastern"
    });

    return formatted;
  }

  return (
    <>
      <TabPanel>
        <Box color="neutral800" padding={6} background="neutral0">
          {isProcessLogsLoading ? (
            <LoadingSpinner1 />
          ) : (
            <div>
              {processLogsData.length === 0 ? (
                <>Log empty.</>
              ) : (
                <div className="font-mono text-sm">
                  <RawTable colCount="2" rowCount={processLogsData.length}>
                  <RawTbody>
                    {processLogsData.map((log, index) => (
                      <RawTr key={index}>
                        <RawTd className="min-w-56 text-slate-500">{formatLogDate(log.timestamp)}</RawTd>
                        <RawTd className="whitespace-pre-wrap">
                          <MuiAccordion 
                            sx={{
                              backgroundColor: "transparent",
                              color: "white",
                              boxShadow: "none",
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon className="text-slate-500" />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              sx={{
                                padding: "0",
                              }}
                            >
                              {log.message.split('\n').length > 1 ? `${log.message.split('\n')[0]}...` : log.message}
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{
                                paddingLeft: "0",
                                paddingRight: "0",
                              }}
                            >
                              {log.message}
                            </AccordionDetails>
                          </MuiAccordion>
                        </RawTd>
                      </RawTr>
                    ))}
                  </RawTbody>
                  </RawTable>
                </div>
              )}
            </div>
          )
        }
        </Box>
      </TabPanel>
    </>
  );
}

export default ClaimDetailLogs;