import { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { GlobalContextState } from "./context/globalContext";
import "../src/styles.css";

import Home from "./pages/Home";
import Dashboard from './pages/Dashboard';
import Document from './pages/Document';
import Settings from './pages/Settings';
import Claims from "./pages/Claims";
import BodyShopRegister from './pages/BodyShopRegister';
import ResetPassword from './pages/ResetPassword';
import DeleteAccount from './pages/DeleteAccount';
import Logout from "./pages/Logout";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function NotFound() {
  return <h1>Not Found</h1>;
}

function PrivateRoute({ children }) {
  const { loggedIn } = useContext(GlobalContextState);
  return loggedIn ? children : <Redirect to="/" />;
}

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <Home />} />
        <Route path="/body-shop-registration" render={() => <BodyShopRegister />} />
        <Route path="/reset-password" render={() => <ResetPassword />} />
        <Route path="/settings">
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        </Route>
        <Route path="/request-delete-account">
          <PrivateRoute>
            <DeleteAccount />
          </PrivateRoute>
        </Route>
        <Route path="/dashboard">
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        </Route>
        <Route path="/documents">
          <PrivateRoute>
            <Document />
          </PrivateRoute>
        </Route>
        <Route path="/claims">
          <PrivateRoute>
            <Claims />
          </PrivateRoute>
        </Route>

        <Route path="/privacy-policy" render={() => <PrivacyPolicy />} />
        <Route path="/logout" render={() => <Logout />} />
        <Route path="*" render={() => <NotFound />} />
      </Switch>
    </Router>
  );
}

export default App;
