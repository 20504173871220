import React, { useState, useEffect } from 'react';
import YouTubeVideos from './YouTubeVideos';


const YouTubePlayList = ( { youtubeChannelId }) => {
  const [playlists, setPlaylists] = useState([]);
  
  const apiKey = 'AIzaSyDUlRolAiwy2PpG_tIumntu3Q79V_C6vjI';
  const fecthPlaylistUrl = `https://youtube.googleapis.com/youtube/v3/playlists?part=snippet%2CcontentDetails&channelId=${youtubeChannelId}&maxResults=25&key=${apiKey}`;

  useEffect(() => {
    const fetchPlaylist = async () => {
      try {
        const response = await fetch(fecthPlaylistUrl);
        if(response.ok) {
          const data = await response.json();
          setPlaylists(data.items);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchPlaylist();
  }, []);

  return (
    <div>
      {playlists && (
        <>
          {playlists.map((playlist, index) => (
            <div key={index}>
              <YouTubeVideos playListId={playlist.id} apiKey={apiKey} />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default YouTubePlayList;
