import React from "react";
import { useState, useContext, useRef } from "react";
import { Divider } from "@strapi/design-system";
import {
  MainNav,
  NavSections,
  NavCondense,
  NavBrand,
  NavUser,
  NavLink,
  NavFooter
} from "@strapi/design-system/v2";
import {
  Write,
  User,
  Cog,
  Dashboard,
  Book,
  Cross
} from "@strapi/icons";
import { NavLink as RouterNavLink } from "react-router-dom";
import { GlobalContextState } from "../../context/globalContext";
import { Exit } from "@strapi/icons";

export default function SideNav() {
  const [condensed, setCondensed] = useState(false);
  const user = useContext(GlobalContextState).user;
  const buttonRef = useRef(null);
  let navigation = [];

  const icons = {
    Write: <Write />,
    User: <User />,
    Cog: <Cog />,
    Dashboard: <Dashboard />,
    Book: <Book />,
    Exit: <Exit />
  };

  if(user.subscription){
    navigation = [
      {
        name: "Claims",
        pathname: "/claims",
        current: false,
        icon: "Write",
        key: 0
      }
    ];
  }

  function toogleSideNav() {
    const sideNav = document.querySelector('.main-navigation');
    sideNav.classList.toggle('active');
  }

  return (
    <div className="main-navigation">
      <MainNav condensed={condensed}>
        <div className="mobile-nav nav-left">
          {condensed ? (
            <img src="/assets/images/tc-icon-48.png" alt="Strapi logo" />
          ) : (
            <img src="/assets/images/tc_logo_white-49bdf314.png" alt="Strapi logo" />
          )
        }
          
          <button className="mobile-nav__burger" onClick={toogleSideNav}>
            <Cross />
          </button>
        </div>
        <Divider />
        <NavSections>
          {user.superAdmin && (
            <NavLink
            as={RouterNavLink}
            to="/dashboard"
            icon={icons["Dashboard"]}
          >
            Dashboard
          </NavLink>
          )}
          {navigation.map((item, key) => (
            <NavLink
              key={key}
              as={RouterNavLink}
              to={item.pathname}
              icon={icons[item.icon]}
            >
              {item.name}
            </NavLink>
          ))}
          <NavLink
            as={RouterNavLink}
            to="/settings"
            icon={icons["Cog"]}
          >
            Settings
          </NavLink>
          <NavLink
            as={RouterNavLink}
            to="/documents"
            icon={icons["Book"]}
          >
            Documentation
          </NavLink>
          <NavLink
            href="/logout"
            icon={icons["Exit"]}
          >
            Logout
          </NavLink>
        </NavSections>
        <NavFooter>
          <NavUser
            initials={user.username.charAt(0).toUpperCase()}
            ref={buttonRef}
          >
            {user && user.username}
          </NavUser>
          <NavCondense className="desktop-v" onClick={() => setCondensed((s) => !s)}>
            {condensed ? "Expanded the navbar" : "Collapse the navbar"}
          </NavCondense>
        </NavFooter>
      </MainNav>
    </div>
  );
}
